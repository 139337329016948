const ErrorCodes = {
  ERRORS: {
    REQUIRED: "This field is required",

    INVALID_EMAIL: "Invalid email",

    PASSWORD_MISMATCH: "Passwords do not match",
    AUTH: "Incorrect email/password",
    NAME_ERROR: "Name should not greater than 256 characters",
    SPECIAL_CHARS_NOT_ALLOWED: "Special characters are not allowed",
    PASSWORD_ERROR:
      "Password should contain minimum six characters, at least one letter, one number and one special character",
  },
};
export default ErrorCodes;
