import React from "react";
import { Button } from "reactstrap";
import styled from "styled-components";
import { withRouter } from "../../withRouter";
import SideBar from "../auth/SideBar";

const Wrapper = styled.div`
  .second-column {
    padding: 28px;
    overflow: hidden;
    background-color: ${(props) => props.theme.colors.white};
  }
  .terms-conditions-text {
    font-weight: 500;
    padding-bottom: 10px;
  }
  .header-content {
    font-weight: 600;
  }
  .headers {
    padding-top: 10px;
    font-weight: 600;
  }
  p {
    margin-bottom: 6px;
  }
  .btn {
    --bs-btn-padding-x: 0px;
  }
  .button-style {
    border: none;
    background-color: ${(props) => props.theme.colors.white};
  }

  .button-style:hover {
    background-color: ${(props) => props.theme.colors.white};
  }
  .terms-conditions-wrapper {
    border: 0.5px solid ${(props) => props.theme.colors.secondary_light};
    padding: 10px;
    height: 460px;
    overflow-x: hidden;
    overflow-y: scroll;
  }
`;

const TermsAndConditions = (props) => {
  const { navigate } = props;
  const goBack = () => {
    navigate && navigate(-1);
  };
  return (
    <Wrapper className="main-wrapper">
      <SideBar />
      <div className="second-column">
        <h4 className="terms-conditions-text">
          <Button className="button-style" onClick={goBack}>
            <span className="icon-20 icon-left-arrow" />{" "}
          </Button>
          {"  "}
          Terms and conditions
        </h4>
        <div className="terms-conditions-wrapper">
          <div className="header-content">
            THIS IS AN AGREEMENT BETWEEN YOU OR THE ENTITY THAT YOU REPRESENT
            (HEREINAFTER "YOU" OR "YOUR") AND INCUBXPERTS TECHNOCONSULTING PVT
            LTD (HEREINAFTER "IncubXperts" ) GOVERNING YOUR USE OF SSL Expiry
            Tracker ( sslexpirytracker.com )
          </div>
          <div className="headers">Description of Service</div>
          <p>
            IncubXperts currently offers SSL Expiry Tracker (
            sslexpirytracker.com ) Service as free service. The Service enables
            you to monitor expiry of SSL certificate of your website . You agree
            to obtain prior verifiable permission from the owners of such
            resources that are being monitored using the Service.
          </p>
          <div className="headers">
            Subscription to SSL expiry tracker Service
          </div>
          <p>
            You may subscribe to SSL Expiry Tracker Service by completing the
            registration process at the SSL Expiry Tracker website. IncubXperts
            reserves the right to modify or discontinue, at any time and from
            time to time, temporarily or permanently, the service (or any part
            thereof) with or without notice. You agree that IncubXperts shall
            not be liable to you or to any third party for any harm related to,
            arising out of, or caused by the modification, suspension or
            discontinuance of the Service for any reason. In consideration of
            your use of the Service, you agree to: a) provide true, accurate,
            current and complete information about yourself as prompted by the
            Registration Process; and b) maintain and promptly update your
            Registration data to keep it true, accurate, current, and complete.
            If you provide any information that is untrue, inaccurate, outdated,
            or incomplete, or if IncubXperts has reasonable grounds to suspect
            that such information is untrue, inaccurate, outdated, or
            incomplete, IncubXperts has the right to terminate your Account and
            refuse current or future use the Service.  Service is offered as-is
            during the free, without any warranty, covenant, support or
            liability whatsoever, to the extent permitted by law.
          </p>
          <div className="headers">Personal Information and Privacy</div>
          <p>
            Personal information you provide to IncubXperts through the Service
            is governed by IncubXperts privacy policy (
            https://www.incubxperts.com/privacy-statement ) and applicable laws
            related to personal information. Your election to use the Service
            indicates your acceptance of the terms of the IncubXperts privacy
            policy ( https://www.incubxperts.com/privacy-statement ). You are
            responsible for maintaining the confidentiality of your Account
            information and other sensitive information. You are responsible for
            all activities that occur in your Account. IncubXperts is in no way
            responsible for any loss or damage to you or to any third party
            incurred as a result of any unauthorized access and/or use of your
            Account, or otherwise.
          </p>
          <div className="Publicity "> </div>
          <p>
            IncubXperts reserves the right to publish your name and logo in the
            customer list on our website, press releases, advertisements,
            brochures and other promotional materials. You should email
            contact@incubxperts.com if you do not wish to permit IncubXperts to
            publish your name in the manner mentioned above.
          </p>
          <div className="headers">Communications from IncubXperts </div>
          <p>
            The Service may include certain communications from IncubXperts,
            such as service announcements, administrative messages and
            newsletters. You understand that these communications shall be
            considered part of using the Services and that you will not be able
            to opt out of receiving them. Further, IncubXperts reserves the
            right to send electronic mail to you, informing you of changes or
            additions to the Terms of Service.{" "}
          </p>
          <div className="headers">Restrictions on use </div>
          <p>
            In addition to all other terms and conditions of this Agreement, you
            shall not: (i) transfer or otherwise make available to any third
            party the services; (ii) provide any service based on the services
            without any prior written permission; (iii) use the third party
            links to sites without agreeing to their website terms & conditions;
            (iv) post any third party link sites including their logo, company
            name, etc. without their prior written permission; or (v)use the for
            illegal purposes; vi) allow user licenses to be shared or used by
            more than one individual other than by way of reassigning the user
            license to a new user; (vii) except as permitted under applicable
            law, attempt to disassemble, reverse engineer or decompile the
            Service; (viii) attempt to gain unauthorized access to the Service
            or its related systems or network; (ix) use the Service to send or
            store material containing software viruses, worms or other harmful
            computer codes, files, scripts or programs; (x) use the Service in
            any manner that interferes with or disrupts the integrity, security
            or performance of the Service, its components and the data contained
            therein; (xi) host, display, upload, modify, publish, transmit,
            store, update or share any information that belongs to another
            person or entity and to which you do not have any right, including
            personal or confidential information of any person or entity with
            respect to which you do not have consent or permission from such
            person or entity; (xii) use the Service for transmitting information
            that is false and untrue, and is written or published in any form,
            with the intent to mislead or harass a person, entity or agency for
            financial gain or to cause any injury to any person; (xiii) violate
            any applicable local, state, national or international law; (xiv)
            use the Service for any form of competitive or benchmarking
            purposes; and (xv) remove or obscure any proprietary or other
            notices contained in the Service.
          </p>
          <div className="headers">Hosting Location</div>
          <p>
            The location of the cloud facility from which you are served depends
            on the mapping of your region/country to the available cloud
            facilities at the time of your sign-up. We may migrate your account
            or require you to migrate your account to a different cloud facility
            in the event of any updates to the region/country to cloud facility
            mapping at any point of time. You must not mask your internet
            protocol (IP) address at the time of sign-up since your
            region/country is determined base on your IP address. If, at any
            time, your actual region/country is found to be different from the
            region/country in our records, IncubXperts may take appropriate
            action such as migrate your account or require you to migrate your
            account to the cloud facility corresponding to your region/country,
            or close your account and deny the Service to you. If you are served
            from a cloud facility outside your region/country and a IncubXperts
            entity has an office in your region/country, apart from storing the
            data in the cloud facility assigned to you, we may store a local
            copy of the data in your region/country.{" "}
          </p>
          <div className="headers">Administrator and Primary Contact </div>
          <p>
            You will be the default administrator of the SSL Expiry Tracker (
            sslexpirytracker.com ) account created by you. An administrator of a
            SSL Expiry Tracker ( sslexpirytracker.com ) account may designate
            any number of users as administrators for such SSL Expiry Tracker (
            sslexpirytracker.com ) account or revoke the administrator
            privileges of any user. There must always be a primary contact (and
            only one primary contact) for a SSL Expiry Tracker (
            sslexpirytracker.com ) account who should also be an administrator.
            By default, the first administrator is the primary contact. An
            administrator may designate any other administrator as the primary
            contact. All communications in respect of a SSL Expiry Tracker (
            sslexpirytracker.com ) account will be sent to the primary contact.{" "}
          </p>
          <div className="headers">Trademarks</div>
          <p>
            IncubXperts, IncubXperts logo, SSL Expiry Tracker (
            sslexpirytracker.com ) and SSL Expiry Tracker logo are trademarks of
            IncubXperts TechnoConsulting Private Limited. You agree not to
            display or use, in any manner without prior permission.{" "}
          </p>
          <div className="headers">Disclaimer of Warranties </div>
          <p>
            YOU EXPRESSLY UNDERSTAND AND AGREE THAT THE USE OF THE SERVICE IS AT
            YOUR SOLE RISK. THE SERVICE IS PROVIDED ON AN AS-IS-AND-AS-AVAILABLE
            BASIS. IncubXperts EXPRESSLY DISCLAIMS ALL WARRANTIES OF ANY KIND,
            WHETHER EXPRESS OR IMPLIED, INCLUDING, BUT NOT LIMITED TO, THE
            IMPLIED WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR
            PURPOSE AND NON-INFRINGEMENT. IncubXperts MAKES NO WARRANTY THAT THE
            SERVICE WILL BE UNINTERRUPTED, TIMELY, SECURE, OR VIRUS FREE. USE OF
            THE SERVICE WILL BE AT YOUR OWN DISCRETION AND RISK AND YOU WILL BE
            SOLELY RESPONSIBLE FOR ANY DAMAGE TO YOUR COMPUTER SYSTEM, MOBILE
            TELEPHONE, WIRELESS DEVICE OR DATA THAT RESULTS FROM THE USE OF THE
            SERVICES. NO ADVICE OR INFORMATION, WHETHER WRITTEN OR ORAL,
            OBTAINED BY YOU FROM IncubXperts, ITS EMPLOYEES OR REPRESENTATIVES
            SHALL CREATE ANY WARRANTY NOT EXPRESSLY STATED IN THESE TERMS OF
            SERVICE.
          </p>
          <div className="headers">Limitation of Liability </div>
          <p>
            TO THE MAXIMUM EXTENT PERMITTED BY APPLICABLE LAW, IN NO EVENT SHALL
            IncubXperts BE LIABLE FOR ANY CONSEQUENTIAL, INCIDENTAL, INDIRECT,
            SPECIAL, PUNITIVE, OR OTHER LOSS OR DAMAGE WHATSOEVER OR FOR LOSS OF
            BUSINESS PROFITS, BUSINESS INTERRUPTION, COMPUTER FAILURE, LOSS OF
            BUSINESS INFORMATION, OR OTHER LOSS ARISING OUT OF OR CAUSED BY YOUR
            USE OF OR INABILITY TO USE THE SERVICE, EVEN IF IncubXperts HAS BEEN
            ADVISED OF THE POSSIBILITY OF SUCH DAMAGE. 
          </p>
          <div className="headers">Indemnification</div>
          <p>
            You agree to indemnify and hold harmless IncubXperts, its officers,
            directors, employees, suppliers, and affiliates, from and against
            any losses, damages, fines and expenses (including attorney's fees
            and costs) arising out of or relating to any claims that you have
            used the Service in violation of another party's rights, in
            violation of any law, in violations of any provisions of these Terms
            of Service, or any other claim related to your use of the Service.{" "}
          </p>
          <div className="headers">Termination </div>
          <p>
            You agree that IncubXperts may terminate your Account and access to
            the Service for reasons including, but not be limited to, breaches
            or violations of these Terms of Service or the IncubXperts Privacy
            Policy, a request by you to terminate your Account, discontinuance
            or material modification to the Services, unexpected technical
            issues or problems, extended periods of inactivity and requests by
            law enforcement or other government agencies. Termination of your
            IncubXperts Account includes termination of access to the Service,
            deletion of your Account information such as your e-mail ID and
            Password and deletion of data in your Account as permitted or
            required by law. Usage Limits for SSL Expiry Tracker IncubXperts may
            prescribe usage limits based on the subscription plan. You must make
            sure that Your usage is within the usage limits prescribed by SSL
            Expirty Tracker in order to avail uninterrupted service. You
            understand that SSL Expirty Tracker may restrict an activity if You
            reach the usage limit corresponding to such activity. Modification
            of Terms of Services IncubXperts may modify these Terms of Service
            upon notice to you at any time. You will be provided notice of any
            such modification by electronic mail or by publishing the changes on
            the web page. In the event IncubXperts modifies these Terms of
            Service, you may terminate your use of the Service. Your continued
            use of the Service after notice of any change to the Terms of
            Service will be deemed to be your agreement to the amended Terms of
            Service.
          </p>
          <div className="headers">Governing Law and Jurisdiction</div>
          <p>
            The governing law and jurisdiction that will apply in case of any
            dispute or lawsuit arising out of or in connection with this
            Agreement will be Pune , Maharashtra India. {" "}
          </p>
          <div className="headers">END OF TERMS OF SERVICE </div>
          <p>
            If you have any questions or concerns regarding this agreement,
            please contact us at contact@incubxperts.com {" "}
          </p>
        </div>
      </div>
    </Wrapper>
  );
};

export default withRouter(TermsAndConditions);
