import ErrorCodes from "../constants/errorCodes.constants";
import REGX from "../constants/regx.constants";
export const validateEmail = (email) => {
  if (!email) {
    return ErrorCodes.ERRORS.REQUIRED;
  }

  if (!REGX.EMAIL.test(email)) {
    return ErrorCodes.ERRORS.INVALID_EMAIL;
  }
};
export const validateDomainName = (name) => {
  if (!name) {
    return ErrorCodes.ERRORS.REQUIRED;
  }
  if (!REGX.TEXT_256.test(name)) {
    return ErrorCodes.ERRORS.NAME_ERROR;
  }
};
export const validateStrings = (name) => {
  if (!name) {
    return ErrorCodes.ERRORS.REQUIRED;
  }
  if (!REGX.TEXT_256.test(name)) {
    return ErrorCodes.ERRORS.NAME_ERROR;
  }
  if (!REGX.NO_SPECIAL_CHARACTERS.test(name)) {
    return ErrorCodes.ERRORS.SPECIAL_CHARS_NOT_ALLOWED;
  }
};
export const validatePassword = (password) => {
  if (!password) {
    return ErrorCodes.ERRORS.REQUIRED;
  }
  if (!REGX.PASSWORD.test(password)) {
    return ErrorCodes.ERRORS.PASSWORD_ERROR;
  }
};
export const validateBothPasswords = (password, confirmPassword) => {
  if (!confirmPassword) {
    return ErrorCodes.ERRORS.REQUIRED;
  }
  if (password !== confirmPassword) {
    return ErrorCodes.ERRORS.PASSWORD_MISMATCH;
  }
};
export const validateFields = (user) => {
  const { firstName, lastName, company, email, password } = user;
  if (!(firstName || lastName || company || email || password)) {
    return ErrorCodes.ERRORS.REQUIRED;
  }
  //   const msg = validateEmail(email);

  //   if (msg) {
  //     return msg;
  //   }

  //   return validatePassword(password);
};
