import { applyMiddleware, compose, createStore } from "redux";
import rootReducer from "./reducers/rootReducer.reducer";
import thunk from "redux-thunk";
import { reactReduxFirebase } from "react-redux-firebase";
import config from "./config/fbConfig";
// import { reactReduxFirebase } from "react-redux-firebase";
// import { reduxFirestore, getFirestore } from "redux-firestore";
// import { reduxFirebase, getFirebase } from "react-redux-firebase";

const initialState = {};

const store = createStore(
  rootReducer,
  initialState,
  compose(
    reactReduxFirebase(config, { attachAuthIsReady: true }),
    applyMiddleware(thunk)
  )
);
export default store;
