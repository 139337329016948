import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import { Provider } from "react-redux";
import reportWebVitals from "./reportWebVitals";
import store from "./store";

import theme from "./constants/theme.constants";

import { BrowserRouter } from "react-router-dom";
import { ThemeProvider } from "styled-components";

const root = ReactDOM.createRoot(document.getElementById("root"));

store.firebaseAuthIsReady.then(() => {
  root.render(
    <React.StrictMode>
      <Provider store={store}>
        <BrowserRouter>
          <ThemeProvider theme={theme}>
            {/* <ReactReduxFirebaseProvider {...rrfProps}> */}
            <App />
          </ThemeProvider>
        </BrowserRouter>
        {/* </ReactReduxFirebaseProvider> */}
      </Provider>
    </React.StrictMode>
  );

  // If you want to start measuring performance in your app, pass a function
  // to log results (for example: reportWebVitals(console.log))
  // or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
  reportWebVitals();
});
