const notificationData = [
  {
    mainHeder: "Email",
    icon: "icon-email",
    subHeader:
      "Notification emails will always be delivered to your registered email address, which is",
    labels: [
      { label: "Email notification Enabled" },
      { label: "Alert when certificate is changed" },
    ],
  },
  {
    mainHeder: "Schedule",
    icon: "icon-clock",
    subHeader:
      "Control at what point you'd like to start receiving emails about your expiring certificates.",
    labels: [
      { label: "One day before expiry" },
      { label: "Three days before expiry" },
      { label: "Seven days before expiry" },
      { label: "Fourteen days before expiry" },
      { label: "Twenty days before expiry" },
      { label: "Thirty days before expiry" },
      {
        label: "Sixty days before expiry",
      },
    ],
  },
];
export default notificationData;
