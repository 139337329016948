import { baseURL, googleRecaptchaBaseURL } from "./baseApiURL.constants";
const API_URLS = {
  ADD_USER: `${baseURL}user`,
  GET_DOMIANS: `${baseURL}domain`,
  ADD_DOMAIN: `${baseURL}addUpdateDomain`,
  DELETE_DOMAIN: `${baseURL}domain`,
  ADD_NOTIFICATION_DETAILS: `${baseURL}notifications`,
  GET_NOTIFICATION_DATA: `${baseURL}notifications`,
  GET_USER_DETAILS: `${baseURL}user`,
  CHECK_ALL_CERTIFICATIONS: `${baseURL}domain/refresh`,
  GOOGLE_RECAPTCHA: `${googleRecaptchaBaseURL}recaptcha/api/siteverify`,
};

export { API_URLS };
