import React from "react";
import {
  Form,
  Label,
  Input,
  Button,
  Spinner,
  Alert,
  FormGroup,
  FormText,
  InputGroup,
} from "reactstrap";
import styled from "styled-components";
import { Link } from "react-router-dom";
import SideBar from "./SideBar";

import { getAuthErrormessage } from "../../utilities/utilities";
import Toastr from "../common/Toastr";

const Wrapper = styled.div`
  .mb-3 {
    margin-bottom: 28px !important;
  }
  .btn {
    --bs-btn-padding-x: 40px;
  }

  .second-column {
    padding: 28px;
    background-color: ${(props) => props.theme.colors.white};
  }
  .login-text {
    font-weight: 500;
    padding-bottom: 20px;
  }

  .alert-message {
    margin-left: 6px;
  }
  a {
    text-decoration: none;
    color: ${(props) => props.theme.colors.primary_light};
  }
  a:hover {
    text-decoration: underline;
  }
  .link-wrapper {
    display: grid;
    grid-template-rows: max-content max-content;
    grid-row-gap: 10px;
    margin-top: 30px;
    font-weight: 500;
  }

  .button-wrapper {
    margin-top: 26px;
    display: grid;
    grid-template-columns: max-content max-content;
    grid-gap: 12px;
  }
`;

const LoginForm = (props) => {
  const {
    handleOnChange,
    isLoading,
    handleOnReset,
    fieldErrors,
    authError,
    handleOnSubmit,
    status,
    toastrMessage,
    isFormSubmitted,
  } = props;

  return (
    <Wrapper className="main-wrapper">
      <SideBar />
      <div className="second-column">
        <Toastr openToastr={status} message={toastrMessage} />
        <h4 className="login-text">Login</h4>
        <Form>
          {!isFormSubmitted ? null : (
            <>
              {authError ? (
                <Alert color="danger">
                  <span className="alert-message">
                    {getAuthErrormessage(authError)}
                  </span>
                </Alert>
              ) : null}
            </>
          )}
          <FormGroup>
            <Label className="form-label required" for="email">
              Email
            </Label>
            <InputGroup size="sm">
              <Input
                type="email"
                name="Email"
                id="email"
                placeholder="Email"
                invalid={fieldErrors.email ? true : false}
                onChange={handleOnChange}
              />
            </InputGroup>
            {fieldErrors.email ? (
              <FormText>
                {fieldErrors.email ? fieldErrors.email : null}
              </FormText>
            ) : null}
          </FormGroup>
          <FormGroup>
            <Label className="form-label required" for="password">
              Password
            </Label>
            <InputGroup size="sm">
              <Input
                type="password"
                name="password"
                id="password"
                placeholder="Password"
                onChange={handleOnChange}
                invalid={fieldErrors.password ? true : false}
              />
            </InputGroup>
            {fieldErrors.password ? (
              <FormText>
                {fieldErrors.password ? fieldErrors.password : null}
              </FormText>
            ) : null}
          </FormGroup>

          <div className="button-wrapper">
            <div>
              <Button
                className="me-1"
                color="primary"
                type="submit"
                onClick={handleOnSubmit}
              >
                {isLoading ? <Spinner className="spinner" size="sm" /> : null}
                Login
              </Button>
            </div>
            <div>
              <Button
                onClick={handleOnReset}
                outline
                color="secondary"
                type="reset"
              >
                Reset
              </Button>
            </div>
          </div>
          <div className="link-wrapper">
            <div>
              <Link to="/resetpassword">Forgot Password?</Link>
            </div>
            <div>
              Not registered?<Link to="/signUp"> Create an account</Link>
            </div>
          </div>
        </Form>
      </div>
    </Wrapper>
  );
};

export default LoginForm;
