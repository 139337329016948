import React from "react";
import {
  Col,
  Card,
  Form,
  Label,
  Spinner,
  Input,
  Button,
  CardBody,
  CardTitle,
  CardHeader,
  FormText,
  Container,
  FormGroup,
  InputGroup,
} from "reactstrap";

import styled from "styled-components";

const Wrapper = styled.div`
  .card {
    margin-right: auto;
    margin-left: auto;
    width: 600px;
    width: 600px;
    max-width: 100%;
  }
  .card-title {
    margin-bottom: 0px;
  }
  .form-label {
    margin-bottom: 4px;
  }
  @media (max-width: 320px) {
    .btn {
      --bs-btn-padding-x: 5px;
      --bs-btn-padding-y: 5px;
    }
  }
`;

const ChangePasswordForm = (props) => {
  const {
    goBack,
    handleOnChange,
    isLoading,
    handleOnChangePassword,
    fieldErrors,
  } = props;
  return (
    <Container fluid="sm">
      <Wrapper>
        <Card>
          <CardHeader>
            <CardTitle tag="h4">Change password</CardTitle>
          </CardHeader>
          <CardBody>
            <Form>
              <FormGroup>
                <Label className="form-label required" for="password">
                  Password
                </Label>
                <InputGroup size="sm">
                  <Input
                    type="password"
                    name="password"
                    id="password"
                    placeholder="Enter new password"
                    onChange={handleOnChange}
                    invalid={fieldErrors.password ? true : false}
                  />
                </InputGroup>

                {fieldErrors.password ? (
                  <FormText>
                    {fieldErrors.password ? fieldErrors.password : null}
                  </FormText>
                ) : null}
              </FormGroup>
              <FormGroup>
                <Label className="form-label required" for="confirmPassword">
                  Confirm Password
                </Label>
                <InputGroup size="sm">
                  <Input
                    type="password"
                    name="password"
                    id="confirmPassword"
                    placeholder="Confirm password"
                    onChange={handleOnChange}
                    invalid={fieldErrors.confirmPassword ? true : false}
                  />
                </InputGroup>

                {fieldErrors.confirmPassword ? (
                  <FormText>
                    {fieldErrors.confirmPassword
                      ? fieldErrors.confirmPassword
                      : null}
                  </FormText>
                ) : null}
              </FormGroup>
              <hr />
              <div className="mt-2">
                <Col sm="12">
                  <div className="d-flex">
                    <Button
                      className="me-1"
                      color="primary"
                      type="submit"
                      onClick={handleOnChangePassword}
                    >
                      {isLoading ? (
                        <Spinner className="spinner" size="sm" />
                      ) : null}
                      Change password
                    </Button>
                    <Button
                      onClick={goBack}
                      outline
                      color="secondary"
                      type="reset"
                    >
                      Cancel
                    </Button>
                  </div>
                </Col>
              </div>
            </Form>
          </CardBody>
        </Card>
      </Wrapper>
    </Container>
  );
};

export default ChangePasswordForm;
