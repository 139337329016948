import React from "react";
import { FormGroup, Button, Label, Input, Spinner, FormText } from "reactstrap";
import styled from "styled-components";

const Wrapper = styled.div`
  // margin-top: 40px;

  .button-wrapper {
    display: grid;
    grid-template-columns: 1fr max-content;
  }
`;

const AddDomainsForm = (props) => {
  const {
    fieldErrors,
    isLoading,
    handleOnFieldChange,
    goBack,
    handleOnAddDomain,
  } = props;
  return (
    <Wrapper className="card">
      <div className="card-body">
        <FormGroup>
          <Label className="required" for="examplePassword">
            Domain Name
          </Label>
          <Input
            id="domainName"
            onChange={handleOnFieldChange}
            invalid={fieldErrors.domainName ? true : false}
          />
          {fieldErrors.domainName ? (
            <FormText>
              {fieldErrors.domainName ? fieldErrors.domainName : null}
            </FormText>
          ) : null}
        </FormGroup>
        <div className="button-wrapper">
          <div>
            <Button color="success" onClick={handleOnAddDomain}>
              {isLoading ? <Spinner size="sm" className="spinner" /> : null}
              Add domain
            </Button>
          </div>
          <div>
            <Button onClick={goBack}>Back</Button>
          </div>
        </div>
      </div>
    </Wrapper>
  );
};

export default AddDomainsForm;
