import axios from "axios";
import { baseURL } from "../constants/baseApiURL.constants";
import { getAccessToken } from "../utilities/utilities";
import { auth } from "./fbConfig";

const instance = axios.create({
  baseURL,
});

instance.interceptors.request.use(
  async (reqConfig) => {
    const accessToken = getAccessToken();
    let headers;
    // const getCurrentUserAuthorization = async () => {};
    const currentUser = auth.currentUser;
    if (currentUser) {
      const token = await currentUser.getIdToken();
      if (token) {
        headers = {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        };
        if (accessToken !== token) {
          localStorage.setItem("token", token);
        }
      } else {
        headers = {
          "Content-Type": "application/json",
        };
      }
    }
    return { ...reqConfig, headers };

    // return getCurrentUserAuthorization();
  },
  (error) => {
    return Promise.reject(error);
  }
);
export default instance;
