import React, { Component } from "react";
import ChangePasswordForm from "./ChangePasswordForm";
import { withRouter } from "../../withRouter";
import { connect } from "react-redux";
import { updatePassword } from "../../actions/auth.actions";
import {
  validateBothPasswords,
  validatePassword,
} from "../../utilities/helpers";
import LoadingState from "../common/LoadingState";

class ChangePassword extends Component {
  constructor(props) {
    super(props);
    const { navigate, authError } = this.props;

    this.state = {
      password: "",
      confirmPassword: "",
      authError: authError,

      fieldErrors: {
        password: "",
        confirmPassword: "",
      },
    };
    this.navigate = navigate;
  }

  validate = (values) => {
    const errors = {};
    if (validatePassword(values.password)) {
      errors.password = validatePassword(values.password);
    }
    if (validateBothPasswords(values.password, values.confirmPassword)) {
      errors.confirmPassword = validateBothPasswords(
        values.password,
        values.confirmPassword
      );
    }
    return errors;
  };

  handleOnChange = (e) => {
    const { fieldErrors } = this.state;
    let errors = fieldErrors;
    this.setState(
      {
        [e.target.id]: e.target.value,
      },
      () => {
        const { password, confirmPassword } = this.state;
        const user = {
          password,
          confirmPassword,
        };
        const errorMsg = this.validate(user);

        switch (e.target.id) {
          case "password":
            errors.password = errorMsg.password;
            break;
          case "confirmPassword":
            errors.confirmPassword = errorMsg.confirmPassword;
            break;
          default:
            break;
        }
        this.setState({
          fieldErrors: errors,
        });
      }
    );
  };
  handleOnChangePassword = (e) => {
    e && e.preventDefault();
    const { password, confirmPassword } = this.state;
    const user = {
      password,
      confirmPassword,
    };
    const errors = this.validate(user);
    const { updatePassword } = this.props;

    if (errors) {
      this.setState({
        fieldErrors: errors,
      });
    }
    if (Object.keys(errors).length > 0) {
      return;
    } else {
      updatePassword(password, this.navigate);
    }
  };

  goBack = () => {
    this.navigate("/dashboard");
  };

  render() {
    const { isLoading } = this.props;
    const { fieldErrors, authError } = this.state;
    return (
      <LoadingState isLoading={!this.navigate}>
        <ChangePasswordForm
          fieldErrors={fieldErrors}
          goBack={this.goBack}
          isLoading={isLoading}
          authError={authError}
          handleOnChange={this.handleOnChange}
          handleOnChangePassword={this.handleOnChangePassword}
        />
      </LoadingState>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    auth: state.firebase.auth,
    isLoading: state.auth.isLoading,
    authError: state.auth.authError,
  };
};
export default connect(mapStateToProps, { updatePassword })(
  withRouter(ChangePassword)
);
