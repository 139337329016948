import React, { Component } from "react";
import UserProfileView from "./UserProfile.view";

import styled from "styled-components";
import { withRouter } from "../../withRouter";
import { connect } from "react-redux";
import { addUser, getUserDetails } from "../../services/api.services";

import LoadingState from "../common/LoadingState";
import { validateStrings } from "../../utilities/helpers";
import { getAccessToken } from "../../utilities/utilities";
import { getToastrInfo } from "../../actions/auth.actions";
import toastrMessage from "../../constants/toastrMessages.constants";

const Wrapper = styled.div``;

class UserProfile extends Component {
  constructor(props) {
    super(props);
    const { navigate } = this.props;
    this.state = {
      user: null,
      isLoading: false,
      fieldErrors: {},
    };
    this.navigate = navigate;
    this.accessToken = getAccessToken();
  }
  componentDidMount() {
    this.getUser();
  }

  getUser = () => {
    const { auth } = this.props;
    if (auth.uid && this.accessToken) {
      this.setState({
        isLoading: true,
      });
      return getUserDetails()
        .then((res) => {
          this.setState({
            isLoading: false,
            user: { ...res.data.data },
          });
        })
        .catch((err) => {
          this.setState({
            isLoading: false,
          });
        });
    }
  };
  validate = (values) => {
    const errors = {};
    if (validateStrings(values.firstName)) {
      errors.firstName = validateStrings(values.firstName);
    }

    if (validateStrings(values.company)) {
      errors.company = validateStrings(values.company);
    }
    return errors;
  };

  handleOnChange = (e) => {
    const { user } = this.state;
    const { fieldErrors } = this.state;
    let errors = fieldErrors;
    e && e.preventDefault();
    this.setState(
      {
        user: {
          ...user,
          [e.target.id]: e.target.value,
        },
      },
      () => {
        const { user } = this.state;
        const errorMsg = this.validate(user);

        switch (e.target.id) {
          case "firstName":
            errors.firstName = errorMsg.firstName;
            break;
          // case "lastName":
          //   errors.lastName = errorMsg.lastName;
          //   break;
          case "company":
            errors.company = errorMsg.company;
            break;
          default:
            break;
        }
        this.setState({
          fieldErrors: errors,
        });
      }
    );
  };
  handleOnUpdateProfile = (e) => {
    e && e.preventDefault();
    const { firstName, lastName, company, email } = this.state.user;
    const { auth, getToastrInfo } = this.props;
    const data = {
      firstName,
      lastName,
      company,
      defaultEmail: email,
    };
    const errors = this.validate(data);
    if (errors) {
      this.setState({
        fieldErrors: errors,
        isLoading: false,
      });
    }
    if (Object.keys(errors).length > 0) {
      return;
    } else {
      this.setState({
        isLoading: true,
      });
      return addUser(data, auth.uid)
        .then((res) => {
          this.setState({
            isLoading: false,
          });
          getToastrInfo("success", toastrMessage.update_profile.success);
          this.getUser();
          this.navigate("/dashboard");
        })
        .catch((err) => {
          this.setState({
            isLoading: false,
          });
          getToastrInfo("error", toastrMessage.update_profile.error);
        });
    }
  };
  goBack = () => {
    this.navigate("/dashboard");
  };

  render() {
    const { user, isLoading, fieldErrors } = this.state;
    return (
      <LoadingState isLoading={!user}>
        <Wrapper>
          <UserProfileView
            user={user}
            fieldErrors={fieldErrors}
            goBack={this.goBack}
            isLoading={isLoading}
            handleOnUpdateProfile={this.handleOnUpdateProfile}
            handleOnChange={this.handleOnChange}
          />
        </Wrapper>
      </LoadingState>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    auth: state.firebase.auth,
  };
};
export default connect(mapStateToProps, { getToastrInfo })(
  withRouter(UserProfile)
);
