import React, { Component } from "react";
import SignUpForm from "./SignUpForm";
import { connect } from "react-redux";
import { userSignUp } from "../../actions/auth.actions";
import { withRouter } from "../../withRouter";
import {
  validateEmail,
  validatePassword,
  validateStrings,
  validateBothPasswords,
} from "../../utilities/helpers";

class SignUp extends Component {
  constructor(props) {
    super(props);
    const {
      // state: {
      //   firebase: { auth },
      // },
      navigate,
    } = this.props;

    this.state = {
      firstName: "",
      lastName: "",
      email: "",
      company: "",
      isFormSubmitted: false,
      password: "",
      confirmPassword: "",
      termsConditions: "",
      recaptchaToken: null,
      fieldErrors: {},
    };
    this.navigate = navigate;
    this.recaptcha = React.createRef();
  }
  validate = (values) => {
    const errors = {};
    if (validateEmail(values.email)) {
      errors.email = validateEmail(values.email);
    }
    if (validateStrings(values.firstName)) {
      errors.firstName = validateStrings(values.firstName);
    }

    if (validatePassword(values.password)) {
      errors.password = validatePassword(values.password);
    }
    if (validateStrings(values.company)) {
      errors.company = validateStrings(values.company);
    }
    if (validateBothPasswords(values.password, values.confirmPassword)) {
      errors.confirmPassword = validateBothPasswords(
        values.password,
        values.confirmPassword
      );
    }

    return errors;
  };

  handleOnChange = (e) => {
    const { fieldErrors } = this.state;
    let errors = fieldErrors;
    this.setState(
      {
        [e.target.id]: e.target.value,
        isFormSubmitted: false,
      },
      () => {
        const { firstName, email, company, password, confirmPassword } =
          this.state;
        const user = {
          firstName,

          email,
          company,
          password,
          confirmPassword,
        };
        const errorMsg = this.validate(user);

        switch (e.target.id) {
          case "firstName":
            errors.firstName = errorMsg.firstName;
            break;

          case "email":
            errors.email = errorMsg.email;
            break;
          case "password":
            errors.password = errorMsg.password;
            break;
          case "confirmPassword":
            errors.confirmPassword = errorMsg.confirmPassword;
            break;
          case "company":
            errors.company = errorMsg.company;
            break;
          default:
            break;
        }
        this.setState({
          fieldErrors: errors,
        });
      }
    );
  };

  handleOnSubmit = (e) => {
    e && e.preventDefault();
    const { userSignUp } = this.props;
    const { firstName, lastName, email, company, password, confirmPassword } =
      this.state;

    const user = {
      firstName,
      lastName,
      email,
      company,
      password,
      confirmPassword,
    };
    const errors = this.validate(user);
    if (errors) {
      this.setState({
        fieldErrors: errors,
      });
    }
    if (Object.keys(errors).length > 0) {
      return;
    } else {
      this.setState({
        isFormSubmitted: true,
      });
      userSignUp(this.state, this.navigate);
    }
  };
  handleOnReset = () => {
    this.setState({
      fieldErrors: {},
      isFormSubmitted: false,
    });
  };
  handleOnRecaptcha = (value) => {
    this.setState({
      recaptchaToken: value,
    });
  };

  handleOnTermsAndConditionsCheckbox = (e) => {
    this.setState({
      [e.target.id]: e.target.checked,
    });
  };

  render() {
    const { authError, isLoading } = this.props;
    const {
      recaptchaToken,

      isFormSubmitted,
      fieldErrors,

      termsConditions,
    } = this.state;

    // if (auth && auth.uid) {
    //   return <Navigate to="/dashboard" />;
    // }
    return (
      <div>
        <SignUpForm
          handleOnChange={this.handleOnChange}
          handleOnSubmit={this.handleOnSubmit}
          isFormSubmitted={isFormSubmitted}
          recaptchaToken={recaptchaToken}
          termsConditions={termsConditions}
          fieldErrors={fieldErrors}
          handleOnRecaptcha={this.handleOnRecaptcha}
          authError={authError}
          isLoading={isLoading}
          recaptcha={this.recaptcha}
          handleOnTermsAndConditionsCheckbox={
            this.handleOnTermsAndConditionsCheckbox
          }
          handleOnReset={this.handleOnReset}
        />
      </div>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    auth: state.firebase.auth,
    isLoading: state.auth.isLoading,
    authError: state.auth.authError,
  };
};
export default connect(mapStateToProps, { userSignUp })(withRouter(SignUp));
