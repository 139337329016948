import React from "react";
import {
  Row,
  Col,
  Alert,
  Form,
  Label,
  Input,
  Button,
  Spinner,
  FormText,
  FormGroup,
  InputGroup,
} from "reactstrap";
import styled from "styled-components";
import { Link } from "react-router-dom";
import { getAuthErrormessage } from "../../utilities/utilities";
import ReCAPTCHA from "react-google-recaptcha";
import SideBar from "./SideBar";

const Wrapper = styled.div`
  .row {
    margin-bottom: 6px;
  }
  .mb-3 {
    margin-bottom: 8px !important;
  }
  .btn {
    --bs-btn-padding-x: 40px;
  }

  .second-column {
    padding: 28px;
    background-color: ${(props) => props.theme.colors.white};
  }
  .sign-up-text {
    font-weight: 500;
    padding-bottom: 20px;
  }
  .form-check-input[type="checkbox"] {
    border-radius: 3.5px;
  }
  .checkbox-element {
    margin-top: 16px;
  }
  .recaptcha-element {
    margin-top: 16px;
  }
  .alert-message {
    margin-left: 6px;
  }
  a {
    text-decoration: none;
    color: ${(props) => props.theme.colors.primary_light};
  }
  a:hover {
    text-decoration: underline;
  }
  .link-wrapper {
    margin-top: 16px;
    font-weight: 500;
  }

  .button-wrapper {
    margin-top: 26px;
    display: grid;
    grid-template-columns: max-content max-content;
    grid-gap: 12px;
  }
`;
const SignUpForm = (props) => {
  const {
    handleOnChange,
    handleOnSubmit,
    authError,
    fieldErrors,
    recaptcha,
    handleOnTermsAndConditionsCheckbox,
    termsConditions,
    isLoading,
    isFormSubmitted,
    recaptchaToken,
    handleOnRecaptcha,
    handleOnReset,
  } = props;

  return (
    <>
      <Wrapper className="main-wrapper">
        <SideBar />

        <div className="second-column">
          <div>
            <h4 className="sign-up-text">Sign up</h4>

            <Form>
              {!isFormSubmitted ? null : (
                <>
                  {authError ? (
                    <Alert color="danger">
                      <span className="alert-message">
                        {getAuthErrormessage(authError)}
                      </span>
                    </Alert>
                  ) : null}
                </>
              )}
              <Row>
                <Col md={6}>
                  <FormGroup>
                    <Label className="form-label required" for="firstName">
                      First Name
                    </Label>
                    <InputGroup size="sm">
                      <Input
                        type="text"
                        className="input-text-style"
                        id="firstName"
                        onChange={handleOnChange}
                        invalid={fieldErrors.firstName ? true : false}
                      />
                    </InputGroup>
                    {fieldErrors.firstName ? (
                      <FormText>
                        {fieldErrors.firstName ? fieldErrors.firstName : null}
                      </FormText>
                    ) : null}
                  </FormGroup>
                </Col>
                <Col md={6}>
                  <FormGroup>
                    <Label className="form-label " for="lastName">
                      Last Name
                    </Label>
                    <InputGroup size="sm">
                      <Input
                        name="Last Name"
                        className="input-text-style"
                        id="lastName"
                        onChange={handleOnChange}
                        invalid={fieldErrors.lastName ? true : false}
                      />
                    </InputGroup>
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col md={6}>
                  <FormGroup>
                    <Label className="form-label required" for="company">
                      Company
                    </Label>
                    <InputGroup size="sm">
                      <Input
                        name="Company"
                        id="company"
                        className="input-text-style"
                        onChange={handleOnChange}
                        invalid={fieldErrors.company ? true : false}
                      />
                    </InputGroup>
                    {fieldErrors.company ? (
                      <FormText>
                        {fieldErrors.company ? fieldErrors.company : null}
                      </FormText>
                    ) : null}
                  </FormGroup>
                </Col>
                <Col md={6}>
                  <FormGroup>
                    <Label className="form-label required" for="email">
                      Email
                    </Label>
                    <InputGroup size="sm">
                      <Input
                        type="email"
                        name="Email"
                        id="email"
                        invalid={fieldErrors.email ? true : false}
                        onChange={handleOnChange}
                      />
                    </InputGroup>
                    {fieldErrors.email ? (
                      <FormText>
                        {fieldErrors.email ? fieldErrors.email : null}
                      </FormText>
                    ) : null}
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col md={6}>
                  <FormGroup>
                    <Label className="form-label required" for="password">
                      Password
                    </Label>
                    <InputGroup size="sm">
                      <Input
                        type="password"
                        name="password"
                        id="password"
                        onChange={handleOnChange}
                        invalid={fieldErrors.password ? true : false}
                      />
                    </InputGroup>
                    {fieldErrors.password ? (
                      <FormText>
                        {fieldErrors.password ? fieldErrors.password : null}
                      </FormText>
                    ) : null}
                  </FormGroup>
                </Col>
                <Col md={6}>
                  <FormGroup>
                    <Label
                      className="form-label required"
                      for="confirmPassword"
                    >
                      Confirm Password
                    </Label>
                    <InputGroup size="sm">
                      <Input
                        type="password"
                        name="password"
                        id="confirmPassword"
                        onChange={handleOnChange}
                        invalid={fieldErrors.confirmPassword ? true : false}
                      />
                    </InputGroup>
                    {fieldErrors.confirmPassword ? (
                      <FormText>
                        {fieldErrors.confirmPassword
                          ? fieldErrors.confirmPassword
                          : null}
                      </FormText>
                    ) : null}
                  </FormGroup>
                </Col>
              </Row>

              <FormGroup className="checkbox-element">
                <Input
                  className="form-check-input"
                  type="checkbox"
                  id="termsConditions"
                  onChange={handleOnTermsAndConditionsCheckbox}
                />{" "}
                <label className="form-check-label">
                  I accept the{" "}
                  <Link to="/terms-conditions">Terms and Conditions</Link>
                </label>
              </FormGroup>
              <FormGroup className="recaptcha-element">
                <ReCAPTCHA
                  sitekey="6LeQZ1UmAAAAAIX9qdyru3QUa_wX5hl_lcT8p5rX"
                  onChange={handleOnRecaptcha}
                  ref={recaptcha}
                />
              </FormGroup>

              <div className="button-wrapper">
                <div>
                  <Button
                    className="me-1"
                    color="primary"
                    type="submit"
                    disabled={!(termsConditions && recaptchaToken)}
                    onClick={handleOnSubmit}
                  >
                    {isLoading ? (
                      <Spinner className="spinner" size="sm" />
                    ) : null}
                    Sign Up
                  </Button>
                </div>
                <div>
                  <Button
                    onClick={handleOnReset}
                    outline
                    color="secondary"
                    type="reset"
                  >
                    Reset
                  </Button>
                </div>
              </div>

              <div className="link-wrapper">
                Already have an account?{" "}
                <span>
                  <Link to="/">Login</Link>
                </span>
              </div>
            </Form>
          </div>
        </div>
      </Wrapper>
    </>
  );
};

export default SignUpForm;
