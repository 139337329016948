import React from "react";
import styled from "styled-components";

import IXLogo from "../../assets/company_logo_bg_white.png";

const FooterWrapper = styled.div`
  position: fixed;
  bottom: 0px;

  width: 100%;
  background-color: ${(props) => props.theme.colors.primary_dark};
  color: white;
  padding: 6px;
  text-align: center;

  .icon-style {
    margin-top: 4px;
  }
`;

const Footer = () => {
  return (
    <div>
      <FooterWrapper>
        <footer>
          Made with <span className="icon-18 icon-heart icon-style"></span>
          {"    "}
          by {"    "}
          <a target="_blank" href="https://incubxperts.com/" rel="noreferrer">
            <img src={IXLogo} height="20px" alt="" />
          </a>
        </footer>
      </FooterWrapper>
    </div>
  );
};

export default Footer;
