import firebase from "firebase";
// import "firebase/compat/auth";


const firebaseConfig = {
  apiKey: "AIzaSyAOnszPpoM3oK4hzP9mqJ8uM5pdz8LVqlA",
  authDomain: "expirytracker-app.firebaseapp.com",
  projectId: "expirytracker-app",
  storageBucket: "expirytracker-app.appspot.com",
  messagingSenderId: "738236512846",
  appId: "1:738236512846:web:8a454bb75863b0a37b611f",
  measurementId: "G-1KFFR5ELGC"
};
const config = firebase.initializeApp(firebaseConfig);

export const auth = firebase.auth();

// firebase.firestore().settings({ timestampsInSnapshots: true });
export default config;
