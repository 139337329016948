import React, { Component } from "react";
import LoginForm from "./LoginForm";
import { userLogin } from "../../actions/auth.actions";
import { connect } from "react-redux";
import { Navigate } from "react-router-dom";
import { withRouter } from "../../withRouter";
import { validateEmail, validatePassword } from "../../utilities/helpers";

import { getAccessToken } from "../../utilities/utilities";

class Login extends Component {
  constructor(props) {
    super(props);
    const {
      // state: {
      //   firebase: { auth },
      // },
      navigate,
    } = this.props;
    this.state = {
      email: "",
      password: "",
      authError: "",
      isFormSubmitted: false,
      fieldErrors: {},
    };
    this.navigate = navigate;
    this.accessToken = getAccessToken();
  }
  handleOnChange = (e) => {
    const { fieldErrors } = this.state;
    let errors = fieldErrors;
    this.setState(
      {
        [e.target.id]: e.target.value,
        isFormSubmitted: false,
      },
      () => {
        const { email, password } = this.state;
        const user = {
          email,
          password,
        };
        const errorMsg = this.validate(user);

        switch (e.target.id) {
          case "email":
            errors.email = errorMsg.email;
            break;
          case "password":
            errors.password = errorMsg.password;
            break;

          default:
            break;
        }
        this.setState({
          fieldErrors: errors,
        });
      }
    );
  };
  validate = (values) => {
    const errors = {};
    if (validateEmail(values.email)) {
      errors.email = validateEmail(values.email);
    }
    if (validatePassword(values.password)) {
      errors.password = validatePassword(values.password);
    }

    return errors;
  };

  handleOnSubmit = (e) => {
    const { userLogin } = this.props;
    const { email, password } = this.state;

    e && e.preventDefault();
    const userData = {
      email,
      password,
    };
    const errors = this.validate(userData);
    if (errors) {
      this.setState({
        fieldErrors: errors,
      });
    } else if (Object.keys(errors).length === 0) {
      this.setState({
        fieldErrors: "",
      });
    }
    if (Object.keys(errors).length > 0) {
      return;
    } else {
      this.setState({
        isFormSubmitted: true,
      });
      userLogin(userData, this.navigate);
    }
  };

  handleOnReset = () => {
    this.setState({
      fieldErrors: {},
      isFormSubmitted: false,
    });
  };
  render() {
    const { isLoading, authError, auth, status, toastrMessage } = this.props;

    const { fieldErrors, isFormSubmitted } = this.state;

    if (auth && auth.uid && this.accessToken) {
      return <Navigate to="/dashboard" />;
    }

    return (
      <div>
        <LoginForm
          authError={authError}
          isLoading={isLoading}
          isFormSubmitted={isFormSubmitted}
          fieldErrors={fieldErrors}
          status={status}
          toastrMessage={toastrMessage}
          handleOnReset={this.handleOnReset}
          handleOnChange={this.handleOnChange}
          handleOnSubmit={this.handleOnSubmit}
        />
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    auth: state.firebase.auth,
    isLoading: state.auth.isLoading,
    toastrMessage: state.auth.toastrMessage,
    status: state.auth.status,
    authError: state.auth.authError,
  };
};
export default connect(mapStateToProps, { userLogin })(withRouter(Login));
