import React, { Component } from "react";
import {
  addNotificationData,
  getNotificationData,
} from "../../services/api.services";
import LoadingState from "../common/LoadingState";
import toastrMessage from "../../constants/toastrMessages.constants";
import { validateEmail } from "../../utilities/helpers";
import NotificationsList from "./NotificationsList";

class Notifications extends Component {
  constructor(props) {
    super(props);
    const { uid, navigate, getToastrInfo } = this.props;

    this.state = {
      checkBoxValues: null,
      getAlerts: false,
      openConfirmationBox: false,
      email: "",
      isLoading: false,
      selectedEmail: "",
      emailList: [],
      changeAlert:false,
      rowId: "",
      spinner: [],
      defaultEmail: "",
      fieldErrors: {},
    };
    this.navigate = navigate;
    this.uid = uid;
    this.getToastrInfo = getToastrInfo;
  }
  componentDidMount() {
    this.getNotificationsData();
  }

  setData = (data) => {
    const {
      oneDay,
      threeDay,
      sevenDay,
      fourteenDay,
      twentyDay,
      thirtyDay,
      sixtyDay,
    } = data;
    let checkBoxValues = {
      oneDay: oneDay,
      threeDay: threeDay,
      sevenDay: sevenDay,
      fourteenDay: fourteenDay,
      twentyDay: twentyDay,
      thirtyDay: thirtyDay,
      sixtyDay: sixtyDay,
    };
    return checkBoxValues;
  };

  getNotificationsData = () => {
    return getNotificationData()
      .then((res) => {
        const { getAlerts, defaultEmail, emails,changeAlert } = res.data.data;
        this.setState({
          checkBoxValues: this.setData(res.data.data),
          getAlerts: getAlerts,
          changeAlert,
          defaultEmail: defaultEmail,
          emailList: [defaultEmail, ...emails],
        });
      })
      .catch((err) => {

      });
  };

  handleOnCheck = (e) => {
    const { checkBoxValues } = this.state;

    this.setState({
      checkBoxValues: {
        ...checkBoxValues,
        [e.target.id]: e.target.checked,
      },
    });
  };
  stopSpinner = (id) => {
    const { spinner } = this.state;
    this.setState({ spinner: spinner.filter((obj) => obj !== id) });
  };
  showSpinner = (id) => {
    const { spinner } = this.state;
    return spinner && spinner.filter((obj) => obj === id).length !== 0;
  };
  activateSpinner = (id) => {
    const { spinner } = this.state;
    this.setState({ spinner: [...spinner, id] });
  };
  validate = (email) => {
    const errors = {};
    if (validateEmail(email)) {
      errors.email = validateEmail(email);
    }
    return errors;
  };
  handleOnOpenConfirmationBox = (rowId) => {
    const { emailList } = this.state;
    const filteredEmailList = emailList.filter((x, index) => index === rowId);

    this.setState((prevState) => ({
      openConfirmationBox: !prevState.openConfirmationBox,
      rowId,
      selectedEmail: filteredEmailList[0],
    }));
  };
  handleOnDeleteEmail = () => {
    const { rowId } = this.state;
    const { emailList } = this.state;
    const filteredEmailList = emailList.filter((x, index) => index !== rowId);

    this.setState(
      {
        emailList: filteredEmailList,
      },
      () => {
        this.handleOnOpenConfirmationBox();
      }
    );
  };

  handleOnSave = (index) => (e) => {
    e && e.preventDefault();
    const { checkBoxValues, getAlerts, defaultEmail, emailList,changeAlert } = this.state;
    const updatedEmailList = emailList.filter(
      (email) => email !== defaultEmail
    );
    const apiData = {
      userId: this.uid,
      data: {
        ...checkBoxValues,
        getAlerts,
        changeAlert,
        emails: [...updatedEmailList],
      },
    };
    this.setState({
      isLoading: true,
    });
    this.activateSpinner(index);
    return addNotificationData(apiData)
      .then(() => {
        this.setState({
          isLoading: false,
        });
        this.getToastrInfo(
          "success",
          toastrMessage.notification_messages.success
        );
        this.getNotificationsData();
        this.stopSpinner(index);
      })
      .catch((err) => {
        this.setState({
          isLoading: false,
        });
        this.getToastrInfo("error", toastrMessage.notification_messages.error);
        this.stopSpinner(index);
      });
  };
  handleOnEmail = (e) => {
    const { fieldErrors } = this.state;
    let errors = fieldErrors;
    this.setState(
      {
        [e.target.id]: e.target.value,
      },
      () => {
        const { email } = this.state;
        let errorMsg = this.validate(email);
        switch (e.target.id) {
          case "email":
            errors.email = errorMsg.email;
            break;
          default:
            break;
        }
        this.setState({
          fieldErrors: errors,
        });
      }
    );
  };

  handleOnEmailAlertCheckbox = (e) => {
    this.setState({
      [e.target.id]: e.target.checked,
    });
  };
  handleOnChangeAlertCheckbox=(e)=>{
    this.setState({
      [e.target.id]: e.target.checked,
    });
  }
  handleOnSaveEmailList = () => {
    const { email, emailList } = this.state;
    const newEmailList = [...emailList];

    const errors = this.validate(email);
    if (errors) {
      this.setState({
        fieldErrors: errors,
      });
    }
    if (Object.keys(errors).length > 0) {
      return;
    } else {
      if (newEmailList.includes(email)) {
        return this.getToastrInfo(
          "error",
          toastrMessage.notification_messages.email_duplicate_error
        );
      } else {
        newEmailList.push(email);
        this.setState({
          emailList: newEmailList,
        });
      }
    }
  };

  render() {
    const {
      checkBoxValues,
      isLoading,
      email,
      emailList,
      openConfirmationBox,
      getAlerts,
      defaultEmail,
      fieldErrors,
      selectedEmail,
      changeAlert
    } = this.state;

    return (
      <LoadingState isLoading={!checkBoxValues}>
        <NotificationsList
          checkBoxValues={checkBoxValues}
          getAlerts={getAlerts}
          emailList={emailList}
          fieldErrors={fieldErrors}
          email={email}
          selectedEmail={selectedEmail}
          isLoading={isLoading}
          showSpinner={this.showSpinner}
          handleOnDeleteEmail={this.handleOnDeleteEmail}
          defaultEmail={defaultEmail}
          changeAlert={changeAlert}
          handleOnChangeAlertCheckbox={this.handleOnChangeAlertCheckbox}
          openConfirmationBox={openConfirmationBox}
          handleOnEmail={this.handleOnEmail}
          handleOnOpenConfirmationBox={this.handleOnOpenConfirmationBox}
          handleOnEmailAlertCheckbox={this.handleOnEmailAlertCheckbox}
          handleOnSaveEmailList={this.handleOnSaveEmailList}
          handleOnCheck={this.handleOnCheck}
          handleOnSave={this.handleOnSave}
        />
      </LoadingState>
    );
  }
}
export default Notifications;
