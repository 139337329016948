import React from "react";
import { useState, useEffect } from "react";
import styled from "styled-components";
import { connect } from "react-redux";
import { Toast } from "react-bootstrap";
import { getToastrInfo } from "../../actions/auth.actions";

const Wrapper = styled.div`
  .toast {
    width: 100%;
    margin-bottom: 8px;
    background-color: ${(props) =>
      props.openToastr === "success"
        ? props.theme.colors.pastal_green
        : props.openToastr === "error"
        ? props.theme.colors.danger
        : "transparent"};
    box-shadow: none;
    color: ${(props) =>
      props.openToastr === "success"
        ? props.theme.colors.black
        : props.theme.colors.white};
    font-weight: 500;
    height: 32px;
    --bs-toast-padding-x: 4px;
  }
  .toast-body {
    margin-left: 6px;
  }

  .toast-inner-content-wrapper {
    display: grid;
    grid-template-columns: 1fr max-content;
  }
`;

const Toastr = (props) => {
  const { message, openToastr, getToastrInfo } = props;

  const [isOpen, setOpenState] = useState(false);

  useEffect(() => {
    if (openToastr === "success" || openToastr === "error") {
      setOpenState(true);
    }
  }, [openToastr]);

  setTimeout(() => {
    setOpenState(false);
    if (!isOpen) {
      getToastrInfo("", "");
    }
  }, 6000);

  return (
    <Wrapper openToastr={openToastr}>
      {openToastr === "success" || openToastr === "error" ? (
        <Toast
          onClose={() => setOpenState(false)}
          show={isOpen}
          delay={5000}
          autohide
        >
          <Toast.Body>{message}</Toast.Body>
        </Toast>
      ) : null}
    </Wrapper>
  );
};

export default connect(null, { getToastrInfo })(Toastr);
