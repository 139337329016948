const AuthErrorCodes = [
  { code: "auth/user-not-found", message: "Invalid email" },
  { code: "auth/wrong-password", message: "Invalid password" },
  {
    code: "auth/too-many-requests",
    message:
      "Many failed login attempts, You can immediately restore it by resetting your password or you can try again later.",
  },
  { code: "auth/email-already-in-use", message: "Email already exists" },
];

export default AuthErrorCodes;
