import { auth } from "../config/fbConfig";

import { clearAccessToken, setAccessToken } from "../utilities/utilities";
import {
  SIGNUP_ERROR,
  // SIGNUP_SUCCESS,
  LOGIN_ERROR,
  LOGOUT_ERROR,
  LOGOUT_SUCCESS,
  RESET_PASSWORD_SUCCESS,
  RESET_PASSWORD_ERROR,
  // GET_USER_DATA,
  IS_LOADING,
  API_STATUS,
  TOASTR_MESSAGE,
  // CHECK_USER,
} from "../constants/actionTypes.constants";
import {
  addUser,
  addNotificationData,
  // getUserDetails,
} from "../services/api.services";
import toastrMessage from "../constants/toastrMessages.constants";

const handleOnAddNewUser = (userData, uid, navigate, dispatch) => {
  const { firstName, lastName, company, email } = userData;
  const data = {
    firstName,
    lastName,
    title: "",
    defaultEmail: email,
    company,
  };
  if (uid) {
    return addUser(data, uid)
      .then((res) => {
        navigate && navigate("/dashboard");
        return handleOnAddNotificationData(uid, navigate, dispatch);
      })
      .catch((err) => {
        dispatch({
          type: API_STATUS,
          status: "error",
        });
        dispatch({
          type: TOASTR_MESSAGE,
          toastrMessage: toastrMessage.global_error.error,
        });
      });
  }
};

const handleOnAddNotificationData = (uid, navigate, dispatch) => {
  const apiData = {
    userId: uid,
    data: {
      changeAlert: true,
      getAlerts: true,
      oneDay: true,
      threeDay: true,
      sevenDay: true,
      fourteenDay: true,
      twentyDay: true,
      thirtyDay: true,
      sixtyDay: true,
      emails: [],
    },
  };
  if (uid) {
    return addNotificationData(apiData)
      .then((res) => {
        // userDetails(uid, dispatch, navigate);
        navigate && navigate("/dashboard");
      })
      .catch((err) => {
        dispatch({
          type: API_STATUS,
          status: "error",
        });
        dispatch({
          type: TOASTR_MESSAGE,
          toastrMessage: toastrMessage.global_error.error,
        });
      });
  }
};

// const userDetails = (uid, dispatch, navigate) => {
//   if (uid) {
//     return getUserDetails(uid)
//       .then((res) => {
//         dispatch({
//           type: GET_USER_DATA,
//           user: { ...res.data.data },
//         });
//         navigate && navigate("/dashboard");
//       })
//       .catch((err) => {
//         if (err.response.data.message === "user dosen't exists") {
//           auth.currentUser
//             .delete()
//             .then(() => {
//               dispatch({
//                 type: CHECK_USER,
//                 message:
//                   "Your account is not created successfully kindly sign up again",
//               });
//             })
//             .catch(() => {
//               dispatch({
//                 type: CHECK_USER,
//                 message: "User is not deleted",
//               });
//             });

//           // getToastrInfo(
//           //   "error",
//           //   "Your account is not created successfully kindly sign up again"
//           // );
//           // navigate && navigate("/signUp");
//         }
//         // getToastrInfo("error", toastrMessage.global_error.error);
//       });
//   }
// };

const userSignUp = (newUser, navigate) => {
  return (dispatch) => {
    dispatch({
      type: IS_LOADING,
      isLoading: true,
    });
    dispatch({
      type: SIGNUP_ERROR,
      err: null,
    });

    return auth
      .createUserWithEmailAndPassword(newUser.email, newUser.password)
      .then((res) => {
        const { user } = res;

        // dispatch({
        //   type: SIGNUP_SUCCESS,
        //   res,
        // });
        dispatch({
          type: API_STATUS,
          status: "success",
        });
        dispatch({
          type: TOASTR_MESSAGE,
          toastrMessage: "You have successfully logged in",
        });
        dispatch({
          type: IS_LOADING,
          isLoading: false,
        });
        setAccessToken();
        handleOnAddNewUser(newUser, user.uid, navigate, dispatch);
      })
      .catch((err) => {
        dispatch({
          type: IS_LOADING,
          isLoading: false,
        });
        dispatch({ type: SIGNUP_ERROR, err: err.code, navigateUser: false });
      });
  };
};

const userLogin = (loginCredentials, navigate) => {
  const { email, password } = loginCredentials;
  return (dispatch) => {
    dispatch({
      type: IS_LOADING,
      isLoading: true,
    });
    dispatch({
      type: LOGIN_ERROR,
      err: null,
    });
    return auth
      .signInWithEmailAndPassword(email, password)
      .then((res) => {
        dispatch({
          type: API_STATUS,
          status: "success",
        });
        dispatch({
          type: TOASTR_MESSAGE,
          toastrMessage: toastrMessage.login.success,
        });
        dispatch({
          type: IS_LOADING,
          isLoading: false,
        });
        setAccessToken();
        navigate && navigate("/dashboard");
        // userDetails(user.uid, dispatch, navigate);
      })
      .catch((err) => {
        dispatch({
          type: IS_LOADING,
          isLoading: false,
        });
        dispatch({ type: LOGIN_ERROR, err: err.code });
      });
  };
};

const logOut = (navigate) => {
  return (dispatch) => {
    // dispatch({
    //   type: IS_LOADING,
    //   isLoading: true,
    // });
    // dispatch({ type: LOGOUT_ERROR, err: null });
    return auth
      .signOut()
      .then((res) => {
        dispatch({
          type: LOGOUT_SUCCESS,
        });
        dispatch({
          type: IS_LOADING,
          isLoading: false,
        });
        clearAccessToken();
        navigate && navigate("/");
      })
      .catch((err) => {
        dispatch({
          type: IS_LOADING,
          isLoading: false,
        });

        dispatch({ type: LOGOUT_ERROR, err: err.code });
      });
  };
};
const forgotPassword = (email) => {
  return (dispatch) => {
    dispatch({
      type: IS_LOADING,
      isLoading: true,
    });
    dispatch({
      type: RESET_PASSWORD_ERROR,
      err: null,
    });
    return auth
      .sendPasswordResetEmail(email)
      .then((res) => {
        dispatch({
          type: IS_LOADING,
          isLoading: false,
        });
        dispatch({
          type: RESET_PASSWORD_SUCCESS,
          message: "success",
        });
      })
      .catch((err) => {
        dispatch({
          type: IS_LOADING,
          isLoading: false,
        });
        dispatch({ type: RESET_PASSWORD_ERROR, err: err.code });
      });
  };
};

const getToastrInfo = (status, toastrMessage) => {
  return (dispatch) => {
    dispatch({
      type: API_STATUS,
      status,
    });
    dispatch({
      type: TOASTR_MESSAGE,
      toastrMessage,
    });
  };
};

const updatePassword = (password, navigate) => {
  return (dispatch) => {
    dispatch({
      type: IS_LOADING,
      isLoading: true,
    });
    return auth.currentUser
      .updatePassword(password)
      .then((res) => {
        dispatch({
          type: API_STATUS,
          status: "success",
        });
        dispatch({
          type: TOASTR_MESSAGE,
          toastrMessage: toastrMessage.change_password.success,
        });
        dispatch({
          type: IS_LOADING,
          isLoading: false,
        });
        navigate && navigate("/dashboard");
      })
      .catch((err) => {
        if (err.code === "auth/requires-recent-login") {
          dispatch({
            type: IS_LOADING,
            isLoading: false,
          });

          return auth.signOut();
        } else {
          dispatch({
            type: API_STATUS,
            status: "error",
          });
          dispatch({
            type: TOASTR_MESSAGE,
            toastrMessage: toastrMessage.change_password.error,
          });
          dispatch({
            type: IS_LOADING,
            isLoading: false,
          });
        }
      });
  };
};

const deleteCurrentUser = () => {
  return (dispatch) => {
    auth.currentUser
      .delete()
      .then((res) => {})
      .catch((err) => {
        console.log(err);
      });
  };
};

export {
  userSignUp,
  getToastrInfo,
  userLogin,
  logOut,
  updatePassword,
  forgotPassword,
  deleteCurrentUser,
};
