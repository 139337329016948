/* eslint-disable array-callback-return */
import React, { Component } from "react";
import { Button } from "reactstrap";
import { connect } from "react-redux";
import { Spinner } from "reactstrap";
import styled from "styled-components";
import {
  checkAllCertifications,
  deleteDomain,
  getDomains,
} from "../../../services/api.services";
import LoadingState from "../../common/LoadingState";
import DomainsList from "./DomainsList";
import toastrMessage from "../../../constants/toastrMessages.constants";
import * as XLSX from "xlsx";
import {
  currentDate,
  getFormattedTimeAndDate,
} from "../../../utilities/utilities";

const DomainsWrapper = styled.div`
  margin-bottom: 80px;
  .btn-primary {
    border: 1px solid transparent;
    color: ${(props) => props.theme.colors.white};
    font-weight: 700;
  }
  .card-body {
    padding: 12px;
  }
  .download-button {
    width: 110px;
    margin-left: auto;
    margin-right: 10px;
    margin-bottom: 10px;
  }

  .button-wrapper {
    margin-top: 2px;
  }
  .table-list-button-wrapper {
    display: grid;
    grid-template-columns: 1fr max-content;
  }
  .icon-style {
    margin-top: 2px;
  }

  .search-wrapper {
    width: 100%;
    margin-top: 10px;
  }
  @media (max-width: 320px) {
    .table-list-button-wrapper {
      display: block;
    }
    .certificates-button {
      margin-top: 10px;
    }
    .btn {
      --bs-btn-padding-x: 5px;
      --bs-btn-padding-y: 5px;
    }
  }
`;

class Domains extends Component {
  constructor(props) {
    super(props);
    const { accessToken, uid, navigate,getToastrInfo } = this.props;
    this.state = {
      domains: null,
      openConfirmationBox: false,
      isLoading: false,
      searchText: "",
      selectedDomainName: "",
      updatedDomainList: [],
      id: "",
    };
    this.uid = uid;
    this.accessToken = accessToken;
    this.navigate = navigate;
    this.getToastrInfo=getToastrInfo;
  }

  componentDidMount() {
    this.getDomainsList();
  }

  getDomainsList = () => {
    const { uid, accessToken } = this.props;
    if (uid && accessToken) {
      return getDomains()
        .then((res) => {
          this.setState({
            domains: res.data.data || [],
            updatedDomainList: res.data.data || [],
          });
        })
        .catch((err) => {
          this.getToastrInfo("error", toastrMessage.global_error.error);
        });
    }
  };
  navigateToAddDomains = () => {
    this.navigate("/add-domains");
  };
  handleOnOpenConfirmationBox = (id) => {
    const { domains } = this.state;
    const filteredDomainsList = domains.filter((domain) => id === domain.id);
    const selectedDomainName = filteredDomainsList.map((x) => x.domain);

    this.setState((prevState) => ({
      openConfirmationBox: !prevState.openConfirmationBox,
      id,
      selectedDomainName: selectedDomainName[0],
    }));
  };

  handleOnExportData = () => {
    const { updatedDomainList } = this.state;
    let updatedDomainObj;

    const updatedExportData = updatedDomainList.map((domain) => {
      updatedDomainObj = {
        Domain: domain.actualDomain,
        Hostname: domain.Hostname ? "Valid" : "Error",
        Validfrom: getFormattedTimeAndDate(domain.validFrom),
        Validto: getFormattedTimeAndDate(domain.validTo),
        Daysremaining: domain.daysRemaining,
        Lastchecked: getFormattedTimeAndDate(domain.lastChecked),
        Alternativenames: domain.alternativeNames.toString(),
        Issuer: domain.issuer.organization,
      };
      delete updatedDomainObj.issuer;
      delete updatedDomainObj.subject;
      return updatedDomainObj;
    });

    let workBook = XLSX.utils.book_new();
    let workSheet = XLSX.utils.json_to_sheet(updatedExportData);

    XLSX.utils.book_append_sheet(
      workBook,
      workSheet,
      `IX_SSL_Tracker_${currentDate()}`
    );
    XLSX.writeFile(workBook, `IX_SSL_Tracker_${currentDate()}.xlsx`);
  };

  handleOnDeleteDomain = () => {
    const { selectedDomainName } = this.state;
    let apiData;

    apiData = {
      selectedDomainName,
    };
    return deleteDomain(apiData)
      .then(() => {
        this.handleOnOpenConfirmationBox();
        this.getDomainsList();
      })
      .catch(() => {
        this.handleOnOpenConfirmationBox();
        this.getToastrInfo("error",toastrMessage.global_error.error)

      });
  };

  handleOnSearch = (e) => {
    this.setState(
      {
        [e.target.id]: e.target.value,
      },
      () => {
        const { searchText, domains } = this.state;
        const updatedDomainList =
          domains &&
          domains.filter((domain) => {
            if (searchText === "") {
              return domain;
            } else if (
              domain.domain.toLowerCase().includes(searchText.toLowerCase())
            ) {
              return domain;
            }
          });
        this.setState({
          updatedDomainList,
        });
      }
    );
  };

  handleOnCheckAllCertifications = (e) => {
    e && e.preventDefault();
    this.setState({
      isLoading: true,
    });
    
    return checkAllCertifications()
      .then((res) => {
        this.setState({
          isLoading: false,
          domains: res.data.data || [],
        });
        this.getDomainsList();
        this.getToastrInfo("success", toastrMessage.certification_messages.success);
      })
      .catch((err) => {
        this.setState({
          isLoading: false,
        });
        this.getToastrInfo("error", toastrMessage.certification_messages.error);
      });
  };

  render() {
    const {
      domains,
      selectedDomainName,
      isLoading,
      openConfirmationBox,
      searchText,
      updatedDomainList,
    } = this.state;

    return (
      <LoadingState isLoading={!domains}>
        <DomainsWrapper>
          <div className="card">
            <div className="card-body ">
              <div className="table-list-button-wrapper">
                <div>
                  <Button
                    onClick={this.navigateToAddDomains}
                    color="primary"
                    className="button-wrapper"
                  >
                    Add domain
                  </Button>
                </div>
                <div>
                  {(updatedDomainList && updatedDomainList.length === 0) ||
                  searchText !== "" ? null : (
                    <Button
                      color="primary"
                      onClick={this.handleOnCheckAllCertifications}
                      className="certificates-button"
                    >
                      {isLoading ? (
                        <Spinner size="sm" className="spinner" />
                      ) : null}
                      Check all certificates
                    </Button>
                  )}
                </div>
              </div>

              <div className="search-wrapper">
                <form className="d-flex">
                  <input
                    id="searchText"
                    onChange={this.handleOnSearch}
                    className="form-control me-2"
                    type="search"
                    placeholder="Search domains"
                    aria-label="Search"
                  />
                </form>
              </div>
              <DomainsList
                domains={updatedDomainList}
                selectedDomainName={selectedDomainName}
                isLoading={isLoading}
                openConfirmationBox={openConfirmationBox}
                searchText={searchText}
                handleOnOpenConfirmationBox={this.handleOnOpenConfirmationBox}
                handleOnDeleteDomain={this.handleOnDeleteDomain}
              />
            </div>
            {updatedDomainList && updatedDomainList.length === 0 ? null : (
              <Button
                color="primary"
                className="download-button"
                onClick={this.handleOnExportData}
              >
                <span className="icon-16 icon-download icon-style" /> Export
              </Button>
            )}
          </div>
        </DomainsWrapper>
      </LoadingState>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    auth: state.firebase.auth,
    message: state.auth.message,
    authData: state.auth.authData,
  };
};
export default connect(mapStateToProps)(Domains);
