import React, { Component } from "react";
import ForgotPasswordForm from "./ForgotPasswordForm";
import { connect } from "react-redux";
import { forgotPassword } from "../../actions/auth.actions";
import { Navigate } from "react-router-dom";
import { validateEmail } from "../../utilities/helpers";

class ForgotPassword extends Component {
  constructor(props) {
    super(props);

    this.state = {
      email: "",
      authError: "",
      isFormSubmitted: false,
      fieldErrors: {},
    };
  }
  validate = (value) => {
    const errors = {};
    if (validateEmail(value)) {
      errors.email = validateEmail(value);
    }
    return errors;
  };
  handleOnChange = (e) => {
    const { fieldErrors } = this.state;
    let errors = fieldErrors;
    this.setState(
      {
        [e.target.id]: e.target.value,
        isFormSubmitted: false,
      },

      () => {
        const { email } = this.state;

        const errorMsg = this.validate(email);
        switch (e.target.id) {
          case "email":
            errors.email = errorMsg.email;
            break;
          default:
            break;
        }
        this.setState({
          fieldErrors: errors,
        });
      }
    );
  };

  handleOnForgotPassword = (e) => {
    e && e.preventDefault();
    const { forgotPassword } = this.props;
    const { email } = this.state;

    const errors = this.validate(email);
    if (errors) {
      this.setState({
        fieldErrors: errors,
      });
    }
    if (Object.keys(errors).length > 0) {
      return;
    } else {
      this.setState({
        isFormSubmitted: true,
      });
      forgotPassword(email);
    }
  };

  render() {
    const { auth, authError, isLoading, message } = this.props;
    const { fieldErrors, isFormSubmitted } = this.state;
    return (
      <div>
        {auth && auth.uid ? (
          <Navigate to="/dashboard" />
        ) : (
          <ForgotPasswordForm
            isFormSubmitted={isFormSubmitted}
            isLoading={isLoading}
            message={message}
            authError={authError}
            fieldErrors={fieldErrors}
            handleOnChange={this.handleOnChange}
            handleOnForgotPassword={this.handleOnForgotPassword}
          />
        )}
      </div>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    auth: state.firebase.auth,
    isLoading: state.auth.isLoading,
    message: state.auth.message,
    authError: state.auth.authError,
  };
};

export default connect(mapStateToProps, { forgotPassword })(ForgotPassword);
