import "./styles/App.css";
import "./styles/icons.scss";
import "./styles/style.scss";
import "bootstrap/dist/css/bootstrap.min.css";
import SignUp from "./components/auth/SignUp";
import Login from "./components/auth/Login";
import { Routes, Route } from "react-router-dom";
import Dashboard from "./components/dashboard/Dashboard";
import ForgotPassword from "./components/auth/ForgotPassword";
import AddDomains from "./components/add-domains/AddDomains";
import UserProfile from "./components/user-profile/UserProfile";
import ChangePassword from "./components/change-password/ChangePassword";
import ComponentsWrapper from "./components/navbar-footer/ComponentsWrapper";
import TermsAndConditions from "./components/common/Terms&Conditions";
function App() {
  return (
    <div className="App">
      <Routes>
        <Route path="/signUp" element={<SignUp />} />
        <Route path="/" element={<Login />} />

        <Route path="/resetpassword" element={<ForgotPassword />} />
        <Route path="/terms-conditions" element={<TermsAndConditions />} />
        <Route element={<ComponentsWrapper />}>
          <Route path="/dashboard" element={<Dashboard />} />

          <Route path="/notifications" element={<Dashboard />} />
          <Route path="/add-domains" element={<AddDomains />} />
          <Route path="/user-profile" element={<UserProfile />} />
          <Route path="/change-password" element={<ChangePassword />}></Route>
        </Route>
      </Routes>
    </div>
  );
}

export default App;
