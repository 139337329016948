import React from "react";
import { Alert } from "reactstrap";

const AlertBox = (props) => {
  const { message, color } = props;
  return (
    <div>
      <Alert color={color}>{message}</Alert>
    </div>
  );
};

export default AlertBox;
