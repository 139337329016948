import React from "react";
import { Table, Badge, Button } from "reactstrap";
import ModalHandler from "../../common/ModalHandler";
import EmptyState from "../../common/EmptyState";
import styled from "styled-components";
import { getFormattedTimeAndDate } from "../../../utilities/utilities";

const TableWrapper = styled.div`
  margin-top: 10px;
  text-align: left;
  padding: 4px;
  .badge-style {
    margin-left: 16px;
  }
  .table > :not(caption) > * > * {
    padding: 8px;
  }

  .first-column {
    width: 82px;
  }
  .button-style {
    border: none;
    background-color: transparent;
  }

  .button-style:hover {
    background-color: transparent;
  }
`;
const THead = styled.thead`
  background-color: ${(props) => props.theme.colors.secondary_dark};
`;
const TableHeader = styled.th`
  color: ${(props) => props.theme.colors.white};
  font-weight: 700;
`;

const getRemainingDays = (remainingDays) => {
  if (remainingDays === 0) {
    return <Badge color="danger">Expiring today</Badge>;
  }
  if (remainingDays < 0) {
    return <Badge color="danger">Expired</Badge>;
  }
  if (remainingDays > 0) {
    if (remainingDays >= 1 && remainingDays <= 30) {
      return <Badge color="danger">{remainingDays} days</Badge>;
    }
    if (remainingDays <= 60 && remainingDays >= 31) {
      return <Badge color="warning">{remainingDays} days</Badge>;
    }
    if (remainingDays >= 60) {
      return <Badge color="success">{remainingDays} days</Badge>;
    }
  }
};

const TableBody = (props) => {
  const {
    domainName,
    openConfirmationBox,
    lastChecked,
    expiryDate,
    issuer,
    rowId,
    selectedDomainName,
    remainingDays,
    hostname,
    handleOnDeleteDomain,
    handleOnOpenConfirmationBox,
  } = props;

  return (
    <>
      <tr>
        <td className="first-column">{getRemainingDays(remainingDays)}</td>
        <td className="second-column">{domainName}</td>
        <td>{issuer}</td>
        <td>{getFormattedTimeAndDate(expiryDate)}</td>
        <td>{getFormattedTimeAndDate(lastChecked)}</td>
        <td>
          {hostname ? (
            <Badge className="badge-style" color="success">
              Ok
            </Badge>
          ) : (
            <Badge className="badge-style" color="danger">
              Error
            </Badge>
          )}
        </td>
        <td>
          {
            <div>
              <Button
                onClick={() => handleOnOpenConfirmationBox(rowId)}
                // id={rowId}
                className="button-style"
                type="button"
              >
                <span className="icon-16 icon-delete" />
              </Button>

              <ModalHandler
                modalHeader={`Delete ${selectedDomainName} domain`}
                modalLabel="Are you sure you want to delete domain ?"
                buttonLabel="Delete"
                handleOnOpenConfirmationBox={() =>
                  handleOnOpenConfirmationBox(rowId)
                }
                openConfirmationBox={openConfirmationBox}
                handleOnButtonClick={handleOnDeleteDomain}
              />
            </div>
          }
        </td>
      </tr>
    </>
  );
};

const DomainsList = (props) => {
  const {
    domains,
    selectedDomainName,
    handleOnDeleteDomain,
    handleOnOpenConfirmationBox,
    openConfirmationBox,
  } = props;

  if (domains && domains.length === 0) {
    return (
      <EmptyState
        message="No domains found"
        altMessage="To add a domain click on 'Add domain' button "
      />
    );
  }
  return (
    <>
      <>
        <TableWrapper>
          <Table hover responsive>
            <THead>
              <tr>
                <TableHeader></TableHeader>
                <TableHeader>Domain</TableHeader>
                <TableHeader>Issuer</TableHeader>
                <TableHeader>Expiry Date</TableHeader>
                <TableHeader>Last Checked</TableHeader>
                <TableHeader>HTTPS Status</TableHeader>
                <TableHeader />
              </tr>
            </THead>
            <tbody>
              {domains.map((domain, index) => {
                return (
                  <TableBody
                    key={index}
                    rowId={domain.id}
                    selectedDomainName={selectedDomainName}
                    remainingDays={domain.daysRemaining}
                    hostname={domain.Hostname}
                    openConfirmationBox={openConfirmationBox}
                    handleOnDeleteDomain={handleOnDeleteDomain}
                    handleOnOpenConfirmationBox={handleOnOpenConfirmationBox}
                    domainName={domain.domain}
                    issuer={domain.issuer.organization}
                    lastChecked={domain.lastChecked}
                    expiryDate={domain.validTo}
                    validFrom={domain.validFrom}
                  />
                );
              })}
            </tbody>
          </Table>
        </TableWrapper>
      </>
    </>
  );
};

export default DomainsList;
