const toastrMessage = {
  login: {
    success: "You have successfully logged in",
    error: "Something went wrong",
  },
  global_error: {
    error: "Something went wrong",
  },
  certification_messages: {
    success: "All certifications are checked successfully",
    error: "Something went wrong while doing check certification",
  },
  add_domain: {
    success: "Domain added successfully",
    error: "Invalid domain name",
  },
  notification_messages: {
    success: "Notification settings updated successfully",
    error: "Something went wrong while doing changing notification settings",
    email_duplicate_error:"The email already exists in the email list"
  },
  update_profile: {
    success: "Profile updated successfully",
    error: "Something went wrong while updating profile",
  },
  change_password: {
    success: "Password changed successfully",
    error: "Something went wrong while changing the password",
  },
  logout:{
    success:"Session is timed out kindly login again",
    error:"Something went wrong kindly log out using log out button"
  }
};
export default toastrMessage;
