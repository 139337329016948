import axiosInstance from "../config/apiConfig";

import { API_URLS } from "../constants/api.constants";

const addUser = (userData, userId, accessToken) => {
  const apiData = {
    data: { ...userData },
    userId,
    accessToken,
  };
  return axiosInstance.post(API_URLS.ADD_USER, apiData);
};

const getDomains = () => {
  return axiosInstance.get(`${API_URLS.GET_DOMIANS}`);
};

const addDomain = (apiData) => {
  return axiosInstance.post(API_URLS.ADD_DOMAIN, { ...apiData });
};

const deleteDomain = (apiData) => {
  return axiosInstance.delete(
    `${API_URLS.DELETE_DOMAIN}/${apiData.selectedDomainName}`
  );
};

const addNotificationData = (apiData) => {
  return axiosInstance.post(API_URLS.ADD_NOTIFICATION_DETAILS, apiData);
};
const getNotificationData = () => {
  return axiosInstance.get(
    `${API_URLS.GET_NOTIFICATION_DATA}`
  );
};

const getUserDetails = () => {
  return axiosInstance.get(`${API_URLS.GET_USER_DETAILS}`);
};
const checkAllCertifications = () => {
  return axiosInstance.put(`${API_URLS.CHECK_ALL_CERTIFICATIONS}`);
};
// const verifyGoogleRecaptcha = (token) => {
//   const queryParams = {
//     secret: "6LeIxAcTAAAAAGG-vFI1TnRWxMZNFuojJ4WifJWe",
//   };
//   return axios.post(
//     `${API_URLS.GOOGLE_RECAPTCHA}?"secret=6LeIxAcTAAAAAGG-vFI1TnRWxMZNFuojJ4WifJWe&response=${token}`
//   );
// };

export {
  addUser,
  getNotificationData,
  getDomains,
  getUserDetails,
  checkAllCertifications,
  addDomain,
  // verifyGoogleRecaptcha,
  addNotificationData,
  deleteDomain,
};
