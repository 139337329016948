import React from "react";
import styled from "styled-components";
const Wrapper = styled.div`
  color: ${(props) => props.theme.colors.secondary_darker};
  text-align: center;
  margin-top: 10px;
`;

const EmptyState = (props) => {
  const { message, altMessage } = props;
  return (
    <Wrapper>
      {message}
      <br />
      {altMessage}
    </Wrapper>
  );
};

export default EmptyState;
