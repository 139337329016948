import React from "react";
import {
  Form,
  FormText,
  Label,
  Alert,
  Input,
  Button,
  Spinner,
  InputGroup,
  FormGroup,
} from "reactstrap";
import { Link } from "react-router-dom";
import styled from "styled-components";
import { getAuthErrormessage } from "../../utilities/utilities";
import AlertBox from "../common/AlertBox";
import SideBar from "./SideBar";

const Wrapper = styled.div`
  .mb-3 {
    margin-bottom: 28px !important;
  }

  .second-column {
    padding: 28px;
    background-color: ${(props) => props.theme.colors.white};
  }
  .forgot-password-text {
    font-weight: 500;
    padding-bottom: 20px;
  }
  .btn {
    --bs-btn-padding-x: 40px;
  }
  .alert-message {
    margin-left: 6px;
  }
  a {
    text-decoration: none;
    color: ${(props) => props.theme.colors.primary_light};
  }
  a:hover {
    text-decoration: underline;
  }
  .link-wrapper {
    display: grid;
    grid-template-rows: max-content max-content;
    grid-row-gap: 10px;
    margin-top: 30px;
    font-weight: 500;
  }

  .button-wrapper {
    margin-top: 26px;
    display: grid;
    grid-template-columns: max-content max-content;
    grid-gap: 12px;
  }
`;

const ForgotPasswordForm = (props) => {
  const {
    isLoading,
    handleOnChange,
    authError,
    fieldErrors,
    message,
    isFormSubmitted,
    handleOnForgotPassword,
  } = props;
  return (
    <div>
      <Wrapper className="main-wrapper">
        <SideBar />
        <div className="second-column">
          <h4 className="forgot-password-text">Forgot Password</h4>
          <Form>
            {!isFormSubmitted ? null : message === "success" ? (
              <AlertBox
                color="success"
                message="Reset email is sent chack your inbox"
              />
            ) : null}
            {!isFormSubmitted ? null : (
              <>
                {authError ? (
                  <Alert color="danger">{getAuthErrormessage(authError)}</Alert>
                ) : null}
              </>
            )}
            <FormGroup>
              <Label className="form-label required" for="email">
                Email
              </Label>
              <InputGroup size="sm" className="input-group-merge mb-1">
                <Input
                  type="email"
                  name="Email"
                  id="email"
                  placeholder="Email"
                  onChange={handleOnChange}
                  invalid={fieldErrors.email ? true : false}
                />
              </InputGroup>
              {fieldErrors.email ? (
                <FormText>
                  {fieldErrors.email ? fieldErrors.email : null}
                </FormText>
              ) : null}
            </FormGroup>

            <div className="button-wrapper">
              <Button
                onClick={handleOnForgotPassword}
                color="primary"
                type="reset"
              >
                {isLoading ? <Spinner className="spinner" size="sm" /> : null}
                Reset Password
              </Button>
            </div>
            <div className="link-wrapper">
              <Link to="/">Login</Link>
            </div>
          </Form>
        </div>
      </Wrapper>
    </div>
  );
};

export default ForgotPasswordForm;
