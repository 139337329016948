import React from "react";
import { Modal, Button, ModalBody, ModalFooter, ModalHeader } from "reactstrap";

const ModalHandler = (props) => {
  const {
    openConfirmationBox,
    handleOnOpenConfirmationBox,
    modalHeader,
    handleOnButtonClick,
    modalLabel,
    buttonLabel,
  } = props;

  return (
    <>
      <Modal isOpen={openConfirmationBox} toggle={handleOnOpenConfirmationBox}>
        <ModalHeader toggle={handleOnOpenConfirmationBox}>
          {modalHeader}
        </ModalHeader>
        <ModalBody>{modalLabel}</ModalBody>
        <ModalFooter>
          <Button color="primary" onClick={handleOnButtonClick}>
            {buttonLabel}
          </Button>{" "}
          <Button color="secondary" onClick={handleOnOpenConfirmationBox}>
            Cancel
          </Button>
        </ModalFooter>
      </Modal>
    </>
  );
};
export default ModalHandler;
