const theme = {
  colors: {
    primary_dark: "#053a8c",
    white: "#ffffff",
    black: "#000000",
    secondary_dark: "#6988ba",
    grey: "#c3c3c3",
    light_grey: "rgba(0,0,0,.075)",
    orange: "#ffa500",
    pastal_green: "#c3fcb4",
    primary_light: "#0f85ca",
    danger: "#dc3545",
    primary_lightest: "#0070ff",
    warning50opacity: "rgba(255, 143, 0, 0.5)",
    danger50opacity: "rgba(230, 90, 83, 0.5)",
    secondary_light: "#c3bab8",
    secondary_darker: "#757575",
  },
};

export default theme;
