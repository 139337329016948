import React from "react";
import styled from "styled-components";
import SSLTrackerLogo from "../../assets/SSL_tracker_logo.svg";

const Wrapper = styled.div`
  .logo-wrapper {
    padding: 22px;
    height: 50px;
    width: 200px;
  }
  @media (max-width: 320px) {
    .logo-wrapper {
      padding: 10px;
      width: 100%;
    }
  }
`;

const Logo = () => {
  return (
    <Wrapper>
      <div className="logo-wrapper">
        <img src={SSLTrackerLogo} className="logo" height="60px" alt="" />
      </div>
    </Wrapper>
  );
};

export default Logo;
