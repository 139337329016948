import React, { Component } from "react";
import { connect } from "react-redux";
import { Navigate } from "react-router-dom";

import { withRouter } from "../../withRouter";
import { logOut, getToastrInfo } from "../../actions/auth.actions";
import styled from "styled-components";
import Domains from "./domains/Domains";
import Notifications from "../notifications/Notifications";
import { getAccessToken } from "../../utilities/utilities";

const Wrapper = styled.div`
  height: 100%;
`;

class Dashboard extends Component {
  constructor(props) {
    super(props);
    const {
      // state: {
      //   firebase: { auth },
      // },
      navigate,

      location: { pathname },
    } = this.props;

    this.state = {
      user: null,
    };
    this.navigate = navigate;
    this.accessToken = getAccessToken();
    this.pathname = pathname;
  }

  render() {
    const {
      auth,
      location: { pathname },
      message,
      getToastrInfo,
    } = this.props;
    
    if (!(auth && auth.uid)) {
      return <Navigate to="/" />;
    }
    return (
      <>
        <Wrapper>
          {pathname === "/notifications" ? (
            <Notifications
              uid={auth.uid}
              getToastrInfo={getToastrInfo}
              navigate={this.navigate}
            />
          ) : (
            <>
              <Domains
                navigate={this.navigate}
                uid={auth.uid}
                message={message}
                getToastrInfo={getToastrInfo}
                accessToken={getAccessToken()}
              />
            </>
          )}
        </Wrapper>
      </>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    auth: state.firebase.auth,
    message: state.firebase.message,
    authData: state.auth.authData,
  };
};
export default connect(mapStateToProps, { logOut, getToastrInfo })(
  withRouter(Dashboard)
);
