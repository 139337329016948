import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "../../withRouter";
import { addDomain } from "../../services/api.services";
import AddDomainsForm from "./AddDomainsForm";
import { getToastrInfo } from "../../actions/auth.actions";
import { validateDomainName } from "../../utilities/helpers";

import toastrMessage from "../../constants/toastrMessages.constants";

class AddDomains extends Component {
  constructor(props) {
    super(props);
    const { navigate } = this.props;

    this.state = {
      domainName: "",
      isLoading: false,
      invalid: false,
      fieldErrors: {
        domainName: "",
      },
    };

    this.navigate = navigate;
  }

  validate = (values) => {
    const errors = {};
    if (validateDomainName(values)) {
      errors.domainName = validateDomainName(values);
    }
    return errors;
  };
  handleOnFieldChange = (e) => {
    const { fieldErrors } = this.state;
    let errors = fieldErrors;

    this.setState(
      {
        [e.target.id]: e.target.value,
      },
      () => {
        const { domainName } = this.state;
        const errorMsg = this.validate(domainName);
        switch (e.target.id) {
          case "domainName":
            errors.domainName = errorMsg.domainName;
            break;
          default:
            break;
        }

        this.setState({
          fieldErrors: errors,
        });
      }
    );
  };

  handleOnAddDomain = () => {
    const { auth, getToastrInfo } = this.props;
    const { domainName } = this.state;
    const apiData = {
      userId: auth.uid,
      url: domainName,
    };
    const errors = this.validate(domainName);
    if (errors) {
      this.setState({
        fieldErrors: errors,
        isLoading: false,
      });
    }

    if (Object.keys(errors).length > 0) {
      return;
    } else {
      this.setState({
        isLoading: true,
      });
      return addDomain(apiData)
        .then((res) => {
          this.setState({
            isLoading: false,
          });

          getToastrInfo("success", toastrMessage.add_domain.success);
          this.navigate(-1);
        })
        .catch((err) => {
          this.setState({
            isLoading: false,
          });
          getToastrInfo("error", err.response.data.message);
        });
    }
  };

  goBack = () => {
    this.navigate(-1);
  };

  render() {
    const { invalid, isLoading, fieldErrors } = this.state;
    return (
      <div>
        <AddDomainsForm
          fieldErrors={fieldErrors}
          invalid={invalid}
          isLoading={isLoading}
          goBack={this.goBack}
          handleOnFieldChange={this.handleOnFieldChange}
          handleOnAddDomain={this.handleOnAddDomain}
        />
      </div>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    auth: state.firebase.auth,

    authData: state.auth.authData,
  };
};

export default connect(mapStateToProps, { getToastrInfo })(
  withRouter(AddDomains)
);
