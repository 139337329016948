import {
  LOGIN_ERROR,
  LOGIN_SUCCESS,
  SIGNUP_ERROR,
  SIGNUP_SUCCESS,
  LOGOUT_SUCCESS,
  IS_LOADING,
  // CHECK_USER,
  LOGOUT_ERROR,
  RESET_PASSWORD_SUCCESS,
  RESET_PASSWORD_ERROR,
  GET_USER_DATA,
  API_STATUS,
  TOASTR_MESSAGE,
} from "../constants/actionTypes.constants";

const initState = {
  authData: {},
  isLoading: false,
  message: "",
  status: "",
  toastrMessage: "",
  authError: null,
};
const authReducer = (state = initState, action) => {
  switch (action.type) {
    case SIGNUP_ERROR:
      return {
        ...state,
        authError: action.err,
      };
    case SIGNUP_SUCCESS:
      return {
        ...state,
        authError: null,
      };
    case LOGIN_ERROR:
      return {
        ...state,
        authError: action.err,
      };
    // case CHECK_USER: {
    //   return {
    //     ...state,
    //     message: action.message,
    //   };
    // }
    case LOGIN_SUCCESS:
      return {
        ...state,

        authError: null,
      };

    case GET_USER_DATA:
      return {
        ...state,
        authData: action.user,
      };
    case IS_LOADING:
      return {
        ...state,
        isLoading: action.isLoading,
      };
    case LOGOUT_SUCCESS:
      return { ...state };
    case LOGOUT_ERROR:
      return {
        ...state,
        authError: action.err,
      };
    case RESET_PASSWORD_SUCCESS:
      return {
        ...state,
        message: action.message,
        authError: null,
      };
    case RESET_PASSWORD_ERROR:
      return {
        ...state,
        authError: action.err,
      };

    case API_STATUS:
      return {
        ...state,
        status: action.status,
      };
    case TOASTR_MESSAGE: {
      return {
        ...state,
        toastrMessage: action.toastrMessage,
      };
    }
    default:
      return state;
  }
};
export default authReducer;
