import React from "react";
import styled from "styled-components";
import ThemeImg from "../../assets/SSL-tracker-theme-img.png";
import Logo from "../common/Logo";

const Wrapper = styled.div`
  .img-wrapper {
    margin-top: 22px;
    padding: 6px;
  }

  height: 100%;
  background-color: ${(props) => props.theme.colors.primary_dark};

  .tagline {
    margin-top: 40px;
    padding: 22px 22px 0px 22px;
    color: ${(props) => props.theme.colors.white};
  }
  @media (max-width: 320px) {
    .img-style {
      margin-top: 32px;
      padding: 6px;
      width: 100%;
      height: 50%;
    }
  }
`;

const SideBar = () => {
  return (
    <Wrapper>
      <Logo />

      <h5 className="tagline">
        Prevent downtime of website by tracking SSL certificate expiry date.{" "}
      </h5>

      <div className="img-wrapper">
        <img src={ThemeImg} className="img-style" width="350px" alt="" />
      </div>
    </Wrapper>
  );
};

export default SideBar;
