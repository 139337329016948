const SIGNUP_SUCCESS = "SIGNUP_SUCCESS";
const SIGNUP_ERROR = "SIGNUP_ERROR";
const LOGIN_SUCCESS = "LOGIN_SUCCESS";
const LOGIN_ERROR = "LOGIN_ERROR";
const CURRENT_USER = "CURRENT_USER";
const NAVIGATE_USER = "NAVIGATE_USER";
const LOGOUT_ERROR = "LOGOUT_ERROR";
const LOGOUT_SUCCESS = "LOGOUT_SUCCESS";
const RESET_PASSWORD_SUCCESS = "RESET_PASSWORD_SUCCESS";
const RESET_PASSWORD_ERROR = "RESET-PASSWORD-ERROR";
const GET_USER_DATA = "GET_USER_DATA";
const IS_LOADING = "IS_LOADING";
const UPDATE_PASSWORD_SUCCESS = "UPDATE_PASSWORD_SUCCESS";
const API_STATUS = "API_STATUS";
const TOASTR_MESSAGE = "TOASTR_MESSAGE";
const UPDATE_PASSWORD_ERROR = "UPDATE_PASSWORD_ERROR";
// const CHECK_USER = "CHECK_USER";
export {
  SIGNUP_ERROR,
  SIGNUP_SUCCESS,
  LOGIN_ERROR,
  LOGIN_SUCCESS,
  CURRENT_USER,
  NAVIGATE_USER,
  LOGOUT_SUCCESS,
  LOGOUT_ERROR,
  RESET_PASSWORD_SUCCESS,
  RESET_PASSWORD_ERROR,
  GET_USER_DATA,
  IS_LOADING,
  // CHECK_USER,
  TOASTR_MESSAGE,
  API_STATUS,
  UPDATE_PASSWORD_SUCCESS,
  UPDATE_PASSWORD_ERROR,
};
