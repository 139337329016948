import { useEffect, useState } from "react";
import { auth } from "./config/fbConfig";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";

export const withRouter = (Component) => {
  const Wrapper = (props) => {
    const [currentUser, setCurrentUser] = useState();
    const dispatch = useDispatch();
    useEffect(() => {
      const unsubscribe = auth.onAuthStateChanged((user) => {
        if (user) {
          setCurrentUser(user);
        }
      });
      return unsubscribe;
    }, []);
    const navigate = useNavigate();
    const location = useLocation();

    return (
      <>
        <Component
          location={location}
          currentUser={currentUser}
          navigate={navigate}
          dispatch={dispatch}
          {...props}
        />
      </>
    );
  };
  return Wrapper;
};
