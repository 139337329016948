import React from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";
import { Button, DropdownItem } from "reactstrap";
import SSLTracker from "../../assets/SSL_tracker_logo.svg";
import { UncontrolledDropdown, DropdownToggle, DropdownMenu } from "reactstrap";

import { NavLink } from "react-router-dom";
import ModalHandler from "../common/ModalHandler";

const Wrapper = styled.div`
  .navbar-wrapper {
    background-color: ${(props) => props.theme.colors.primary_dark};
    border-radius: 6px;
    margin-bottom: 10px;
  }
  .font-color {
    color: ${(props) => props.theme.colors.white};
  }
  .btn-secondary {
    padding: 0px;

    background-color: transparent;
  }
  .btn-secondary:hover {
    box-shadow: inset 0 -3em 3em rgb(0 0 0 / 10%), 0 0 0 2px #fff,
      0.3em 0.3em 1em rgb(0 0 0 / 30%);
    background-color: transparent;
  }
  .initials-styling {
    text-transform: uppercase;
    display: inline-block;
    font-size: 20px;
    padding: 10px;
    font-weight: 500;
  }
  .weight {
    font-weight: 600;
  }
  .btn-primary {
    padding: 0px;
    background: transparent;

    border: none;
  }
  .btn-primary:hover {
    background: transparent;
  }
  .avtar-logout-button-wrapper {
    display: grid;
    grid-gap: 24px;
    grid-template-columns: max-content max-content;
    margin-left: auto;
    margin-right: 0;
  }
  .avatar {
    width: 52px;
    height: 50px;
    border-radius: 50%;
    color: white;
    text-align: center;
    background: ${(props) => props.theme.colors.orange};
  }
  .navbar-nav .nav-link.active {
    border-bottom: 2px solid ${(props) => props.theme.colors.white};
    color: ${(props) => props.theme.colors.white};
  }
  .btn-group > :not(.btn-check:first-child) + .btn {
    margin-left: 8px;
  }
  .dropdown-font {
    font-size: 14px;
  }
  .dropdown-menu {
    width: 130px;
  }

  .img-wrapper {
    // margin-top: 20px;
    padding: 4px;
    // height: 80px;
  }
  .logout-button {
    background-color: ${(props) => props.theme.colors.grey};
    border-radius: 50%;
    height: 44px;
    margin-top: 2px;
    text-align: center;
  }
  .navbar-items {
    align-content: flex-end;
  }
  a:active {
    color: ${(props) => props.theme.colors.white};
  }
  a {
    text-decoration: none;
    color: ${(props) => props.theme.colors.black};
  }
`;

const Navbar = (props) => {
  const {
    handleLogOut,
    user,
    handleOnOpenConfirmationBox,
    openConfirmationBox,
  } = props;
  const firstNameInitial =
    user && user.firstName ? user.firstName.charAt(0) : null;
  const lastNameInitial =
    user && user.lastName ? user.lastName.charAt(0) : null;

  return (
    <Wrapper>
      <div className="navbar-wrapper">
        <nav className="navbar navbar-expand-lg navbar-light">
          <div className="container-fluid">
            <Link to="/dashboard" className="navbar-brand font-color" href="#">
              <div className="img-wrapper">
                <img width="100px" src={SSLTracker} alt="" />
              </div>
            </Link>
            <button
              className="navbar-toggler"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#navbarNavAltMarkup"
              aria-controls="navbarNavAltMarkup"
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              <span className="navbar-toggler-icon"></span>
            </button>
            <div className="collapse navbar-collapse" id="navbarNavAltMarkup">
              <div className="navbar-nav navbar-items">
                <div>
                  <NavLink
                    to="/dashboard"
                    className="nav-link font-color weight"
                    aria-current="page"
                    activeclassname="navbar-nav nav-link active"
                  >
                    Domains
                  </NavLink>
                </div>
                <div>
                  <NavLink
                    to="/notifications"
                    className="nav-link font-color weight"
                    aria-current="page"
                    activeclassname="navbar-nav nav-link active"
                  >
                    Notifications
                  </NavLink>
                </div>
              </div>

              <div className="avtar-logout-button-wrapper">
                <div>
                  <UncontrolledDropdown group>
                    <div className="avatar">
                      {" "}
                      <span className="initials-styling">
                        {firstNameInitial}
                        {lastNameInitial}
                      </span>
                    </div>
                    <DropdownToggle
                      className="dropDown-toggle"
                      caret
                      color="primary"
                    />
                    <DropdownMenu>
                      <DropdownItem>
                        <Link
                          to="/user-profile"
                          className="dropdown-font weight"
                        >
                          Edit user profile
                        </Link>
                      </DropdownItem>
                      <DropdownItem>
                        <Link
                          to="/change-password"
                          className="dropdown-font weight"
                        >
                          Change Pasword
                        </Link>
                      </DropdownItem>
                    </DropdownMenu>
                  </UncontrolledDropdown>
                </div>

                <Button
                  className="logout-button"
                  onClick={handleOnOpenConfirmationBox}
                >
                  <span className="icon-42 icon-power-button" />
                </Button>
                <ModalHandler
                  modalHeader="Log out"
                  modalLabel="Are you sure you want to log out?"
                  buttonLabel="Log out"
                  handleOnOpenConfirmationBox={handleOnOpenConfirmationBox}
                  openConfirmationBox={openConfirmationBox}
                  handleOnButtonClick={handleLogOut}
                />
              </div>
            </div>
          </div>
        </nav>
      </div>
    </Wrapper>
  );
};
export default Navbar;
