import moment from "moment";
import { auth } from "../config/fbConfig";
import AuthErrorCodes from "../constants/authErrorCodes.constants";

const setAccessToken = () => {
  auth.currentUser
    .getIdToken()
    .then((res) => {
      if (res) {
        localStorage.setItem("token", res);
      }
    })
    .catch((err) => {
      console.log("err",err);
    });
};

const getDifferenceBetweenTwoDates = (date1, date2) => {
  let firstDate = moment(date1);
  let secondDate = moment(date2);
  return secondDate.diff(firstDate, "days");
};

const getAccessToken = () => {
  return localStorage.getItem("token");
};
const getFormattedTimeAndDate = (date) => {
  return date && moment(date).format("DD-MMM-YYYY hh:mm A").toUpperCase();
};
const clearAccessToken = () => {
  return localStorage.clear();
};

const getAuthErrormessage = (code) => {
  const filteredErrorCode = AuthErrorCodes.filter(
    (errorCode) => errorCode.code === code
  );
  return filteredErrorCode.map((error) => {
    return error.message;
  });
};
const currentDate = () => {
  return moment().format("DD-MMM-YYYY");
};
const getRefreshedToken = () => {
  auth.onIdTokenChanged((user) => {
    if (user) {
      user
        .getIdToken()
        .then((token) => {
          localStorage.setItem("token", token);
        })
        .catch(() => {});
    } else {
      return "";
    }
  });
};
export {
  setAccessToken,
  getFormattedTimeAndDate,
  clearAccessToken,
  getAccessToken,
  getAuthErrormessage,
  currentDate,
  getRefreshedToken,
  getDifferenceBetweenTwoDates,
};
