import React from "react";
import styled from "styled-components";
import {
  Row,
  Col,
  Form,
  Label,
  Input,
  Spinner,
  Button,
  CardHeader,
  CardTitle,
  InputGroup,
  Container,
  FormGroup,
  FormText,
} from "reactstrap";

const Wrapper = styled.div`
  .card {
    margin-right: auto;
    margin-left: auto;
    width: 600px;
    max-width: 100%;
  }
  .form-label {
    margin-bottom: 4px;
  }
  .card-title {
    margin-bottom: 0px;
  }
  .mb-3 {
    margin-bottom: 10px !important;
  }
`;

const UserProfileView = (props) => {
  const { firstName, lastName, email, company } = props.user;
  const {
    handleOnChange,
    fieldErrors,
    isLoading,
    goBack,
    handleOnUpdateProfile,
  } = props;

  return (
    <Container fluid="sm">
      <Wrapper>
        <div className="card">
          <CardHeader>
            <CardTitle tag="h4">User Profile</CardTitle>
          </CardHeader>
          <div className="card-body">
            <Form>
              <Row>
                <Col md={6}>
                  <FormGroup>
                    <Label className="form-label required" for="firstName">
                      First Name
                    </Label>
                    <InputGroup size="sm">
                      <Input
                        type="text"
                        id="firstName"
                        className="input-text-style"
                        // value={firstName}
                        placeholder="First Name"
                        defaultValue={firstName}
                        onChange={handleOnChange}
                        invalid={fieldErrors.firstName ? true : false}
                      />
                    </InputGroup>
                    {fieldErrors.firstName ? (
                      <FormText>
                        {fieldErrors.firstName ? fieldErrors.firstName : null}
                      </FormText>
                    ) : null}
                  </FormGroup>
                </Col>

                <Col md={6}>
                  <FormGroup>
                    <Label className="form-label " for="lastName">
                      Last Name
                    </Label>
                    <InputGroup size="sm" className="input-group-merge mb-1">
                      <Input
                        name="Last Name"
                        id="lastName"
                        className="input-text-style"
                        placeholder="Last Name"
                        defaultValue={lastName}
                        onChange={handleOnChange}
                        invalid={fieldErrors.lastName ? true : false}
                      />
                    </InputGroup>
                  </FormGroup>
                </Col>
              </Row>
              <FormGroup>
                <Row>
                  <Col sm="12">
                    <Label className="form-label required" for="company">
                      Company
                    </Label>
                    <InputGroup size="sm" className="input-group-merge mb-1">
                      <Input
                        name="Company"
                        id="company"
                        placeholder="Company"
                        className="input-text-style"
                        // value={company}
                        defaultValue={company}
                        onChange={handleOnChange}
                        invalid={fieldErrors.company ? true : false}
                      />
                    </InputGroup>
                  </Col>
                </Row>
                {fieldErrors.company ? (
                  <FormText>
                    {fieldErrors.company ? fieldErrors.company : null}
                  </FormText>
                ) : null}
              </FormGroup>
              <Row>
                <Col sm="12">
                  <Label className="form-label required" for="email">
                    Email
                  </Label>
                  <InputGroup size="sm" className="input-group-merge mb-1">
                    <Input
                      type="email"
                      name="Email"
                      id="email"
                      disabled
                      placeholder="Email"
                      // value={email}
                      defaultValue={email}
                      onChange={handleOnChange}
                    />
                  </InputGroup>
                </Col>
              </Row>

              <hr />
              <div className="mt-2">
                <Col sm="12">
                  <div className="d-flex">
                    <Button
                      className="me-1"
                      color="primary"
                      type="submit"
                      onClick={handleOnUpdateProfile}
                    >
                      {isLoading ? (
                        <Spinner size="sm" className="spinner" />
                      ) : null}
                      Update
                    </Button>
                    <Button
                      onClick={goBack}
                      outline
                      color="secondary"
                      type="reset"
                    >
                      Cancel
                    </Button>
                  </div>
                </Col>
              </div>
            </Form>
          </div>
        </div>
      </Wrapper>
    </Container>
  );
};

export default UserProfileView;
