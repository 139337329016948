import React, { Component } from "react";
import { getAccessToken } from "../../utilities/utilities";
import { connect } from "react-redux";
import { withRouter } from "../../withRouter";
import {
  logOut,
  getToastrInfo,
  deleteCurrentUser,
} from "../../actions/auth.actions";
import LoadingState from "../common/LoadingState";
import { getUserDetails } from "../../services/api.services";
import Navbar from "./Navbar";
import styled from "styled-components";
import { Outlet, Navigate } from "react-router-dom";
import Footer from "./Footer";
import Toastr from "../common/Toastr";
const ParentWrapper = styled.div`
  .content-wrapper {
    padding: 8px;
  }

  background-color: #e6ebf3;
`;

class ComponentsWrapper extends Component {
  constructor(props) {
    super(props);
    const { navigate, currentUser } = this.props;

    this.state = {
      user: null,
      uid: null,
      status: "",
      toastrMessage: "",
    };
    this.navigate = navigate;
    this.currentUser = currentUser;
    this.uid = this.currentUser && this.currentUser.uid;
    this.accessToken = getAccessToken();
  }

  componentDidUpdate(prevProps) {
    if (this.props !== prevProps) {
      this.initialize();
    }
  }

  initialize = () => {
    const { auth, getToastrInfo, deleteCurrentUser } = this.props;
    if (auth && auth.uid && this.accessToken) {
      return getUserDetails()
        .then((res) => {
          this.setState({
            user: { ...res.data.data },
          });
        })
        .catch((err) => {
          if (
            err &&
            err.response &&
            err.response.data.message === "user dosen't exists"
          ) {
            getToastrInfo("error", err.response.data.message);
            deleteCurrentUser();
            this.handleLogOut();
          }
          if (
            err &&
            err.response &&
            err.response.data.message === "Unauthorized"
          ) {
            return this.handleLogOut();
          }
          this.handleLogOut();
          // getToastrInfo("error", toastrMessage.global_error.error);
        });
    }
  };

  handleLogOut = () => {
    const { logOut } = this.props;
    logOut(this.navigate);
  };

  handleOnOpenConfirmationBox = () => {
    this.setState((prevState) => ({
      openConfirmationBox: !prevState.openConfirmationBox,
    }));
  };

  render() {
    const { auth, status, toastrMessage } = this.props;
    const { user, openConfirmationBox } = this.state;

    if (!(auth && auth.uid)) {
      return <Navigate to="/" />;
    }
    return (
      <>
        <ParentWrapper>
          <LoadingState isLoading={!auth.uid}>
            <div className="content-wrapper">
              <Navbar
                user={user}
                handleOnOpenConfirmationBox={this.handleOnOpenConfirmationBox}
                handleLogOut={this.handleLogOut}
                openConfirmationBox={openConfirmationBox}
              />
              <Toastr openToastr={status} message={toastrMessage} />
              <Outlet />
            </div>
            <Footer />
          </LoadingState>
        </ParentWrapper>
      </>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    auth: state.firebase.auth,
    toastrMessage: state.auth.toastrMessage,
    status: state.auth.status,
  };
};
export default connect(mapStateToProps, {
  logOut,
  getToastrInfo,
  deleteCurrentUser,
})(withRouter(ComponentsWrapper));
