import authReducer from "./auth.reducer";
import { combineReducers } from "redux";
// import { firestoreReducer } from "react-redux-firebase";
import { firebaseReducer } from "react-redux-firebase";

const rootReducer = combineReducers({
  auth: authReducer,
  // firestore:firestoreReducer,
  firebase:firebaseReducer
});
export default rootReducer;
