import React, { useState } from "react";
import notificationData from "../../constants/notificationData.constants";
import styled from "styled-components";
import { Button } from "reactstrap";
import {
  Accordion,
  AccordionBody,
  AccordionHeader,
  AccordionItem,
  Spinner,
  FormGroup,
  FormText,
  Label,
  InputGroup,
  Input,
} from "reactstrap";
import ModalHandler from "../common/ModalHandler";

const Wrapper = styled.div`
  .icon-style {
    vertical-align: middle;
  }
  .icon-text-wrapper {
    display: grid;
    grid-gap: 5px;
    grid-template-columns: max-content max-content;
  }
  .card-title {
    font-weight: 600;
  }
  .form-control {
    width: 320px;
  }

  .form-check-input {
    cursor: pointer;
  }

  .email-textbox-button-wrapper {
    display: grid;
    margin-top: 8px;
    grid-template-columns: max-content max-content;
    grid-gap: 12px;
    margin-left: 4px;
  }
  .card {
    margin-bottom: ${(props) => (props.index === 0 ? "10px" : "45px")};
  }
  .default-email {
    font-weight: 600;
  }

  .label-checkbox-wrapper {
    display: grid;
    margin-left: 2px;
    grid-gap: 2px;
    grid-template-columns: max-content max-content;
  }
  .checkbox-wrapper {
    display: grid;
    grid-template-rows: max-content;
    grid-row-gap: 1.5px;
  }
  .form-check-label {
    display: grid;
    grid-template-rows: max-content;
    grid-row-gap: 6.8px;
  }

  .submit-button {
    margin-top: 10px;
  }
  .accordion {
    margin-top: 10px;
    width: 442px;
  }
  .form-check-input[type="checkbox"]:disabled {
    background-color: ${(props) => props.theme.colors.secondary_darker};
    border-color: ${(props) => props.theme.colors.secondary_darker};
    cursor: not-allowed;
  }
  .button-style {
    border: none;
    background-color: ${(props) => props.theme.colors.white};
  }

  .button-style:hover {
    background-color: ${(props) => props.theme.colors.white};
  }
  .email-list-defaultEmail {
    font-weight: 600;
    padding-bottom: 10px;
  }

  .accordion-button {
    height: 30px;
  }
  .email-list-wrapper {
    display: grid;
    grid-template-columns: 1fr max-content;
    grid-gap: 6px;
  }
  .email-button {
    margin-top: 31px;
  }
  .label-text {
    font-size: 14px;
  }
  @media (max-width: 320px) {
    .email-textbox-button-wrapper {
      display: block;
    }
    .email-button {
      margin-top: 10px;
    }
    .accordion {
      width: 100%;
    }
  }
`;

const NotificationsList = (props) => {
  const {
    defaultEmail,
    handleOnCheck,
    openConfirmationBox,
    getAlerts,
    emailList,
    email,
    fieldErrors,
    isLoading,
    handleOnEmailAlertCheckbox,
    handleOnSaveEmailList,
    handleOnEmail,
    handleOnSave,
    selectedEmail,
    checkBoxValues,
    handleOnOpenConfirmationBox,
    handleOnDeleteEmail,
    handleOnChangeAlertCheckbox,
    changeAlert,
    showSpinner,
  } = props;

  const [open, setOpen] = useState("1");
  const toggle = (id) => {
    if (open === id) {
      setOpen();
    } else {
      setOpen(id);
    }
  };

  return (
    <>
      {notificationData.map((data, index) => {
        return (
          <Wrapper key={index} index={index} getAlerts={getAlerts} icon={data.icon}>
            <div className="card">
              <div className="card-body">
                <div className="icon-text-wrapper">
                  <div>
                    <span className={`icon-18 ${data.icon} icon-style`} />
                  </div>
                  <div>
                    <h5 className="card-title">{data.mainHeder}</h5>
                  </div>
                </div>
                <p className="card-text">
                  {data.subHeader}{" "}
                  {data.icon === "icon-email" ? (
                    <span className="default-email">{defaultEmail}</span>
                  ) : null}
                </p>
                <div className="label-checkbox-wrapper">
                  <div className="checkbox-wrapper">
                    {data.icon === "icon-email" ? (
                      <>
                        <div className="form-check">
                          <input
                            className="form-check-input"
                            type="checkbox"
                            value={getAlerts}
                            id="getAlerts"
                            defaultChecked={getAlerts}
                            onChange={handleOnEmailAlertCheckbox}
                          />
                        </div>
                        <div className="form-check">
                          {getAlerts ? (
                            <input
                              className="form-check-input"
                              type="checkbox"
                              value={changeAlert}
                              id="changeAlert"
                              defaultChecked={changeAlert}
                              onChange={handleOnChangeAlertCheckbox}
                            />
                          ) : null}
                        </div>
                      </>
                    ) : (
                      Object.keys(checkBoxValues).map((checkbox, index) => {
                        return (
                          <div className="form-check">
                            <div>
                              <input
                                className="form-check-input"
                                type="checkbox"
                                value={checkBoxValues[checkbox]}
                                id={checkbox}
                                disabled={getAlerts ? false : true}
                                defaultChecked={checkBoxValues[checkbox]}
                                onChange={handleOnCheck}
                              />
                            </div>
                          </div>
                        );
                      })
                    )}
                  </div>
                  <div>
                    <label className="form-check-label">
                      {data.labels.map((checkBoxLabel) => (
                        <div className="label-text">
                          {checkBoxLabel.label === "Alert when certificate is changed" && !getAlerts
                            ? null
                            : checkBoxLabel.label}
                        </div>
                      ))}
                    </label>
                  </div>
                </div>

                {data.icon === "icon-email" ? (
                  <>
                    {getAlerts ? (
                      <>
                        <div className="email-textbox-button-wrapper">
                          <div>
                            <FormGroup>
                              <Label
                                className="form-label required"
                                for="Email"
                              >
                                Email
                              </Label>
                              <InputGroup>
                                <Input
                                  name="Email"
                                  id="email"
                                  type="email"
                                  placeholder="Email"
                                  value={email}
                                  onChange={handleOnEmail}
                                  invalid={fieldErrors.email ? true : false}
                                />
                              </InputGroup>
                              {fieldErrors.email ? (
                                <FormText>
                                  {fieldErrors.email ? fieldErrors.email : null}
                                </FormText>
                              ) : null}
                            </FormGroup>
                          </div>
                          <div>
                            <Button
                              className="email-button"
                              onClick={handleOnSaveEmailList}
                            >
                              {" "}
                              Add email
                            </Button>
                          </div>
                        </div>
                        <div>
                          <Accordion open={open} toggle={toggle}>
                            <AccordionItem>
                              <AccordionHeader targetId="1">
                                Emails List
                              </AccordionHeader>
                              <AccordionBody accordionId="1">
                                <div>
                                  {emailList.map((email, index) => (
                                    <div className="email-list-wrapper">
                                      <div>
                                        {" "}
                                        {email === defaultEmail ? (
                                          <div className="email-list-defaultEmail">
                                            {email}
                                          </div>
                                        ) : (
                                          email
                                        )}{" "}
                                      </div>
                                      {email === defaultEmail ? (
                                        <div />
                                      ) : (
                                        <div>
                                          <Button
                                            onClick={() =>
                                              handleOnOpenConfirmationBox(index)
                                            }
                                            // id={rowId}
                                            className="button-style"
                                            type="button"
                                          >
                                            <span className="icon-16 icon-delete" />
                                          </Button>

                                          <ModalHandler
                                            modalHeader={`Delete ${selectedEmail}`}
                                            modalLabel="Are you sure you want to delete this email"
                                            buttonLabel="Delete"
                                            handleOnOpenConfirmationBox={() =>
                                              handleOnOpenConfirmationBox(index)
                                            }
                                            openConfirmationBox={
                                              openConfirmationBox
                                            }
                                            handleOnButtonClick={
                                              handleOnDeleteEmail
                                            }
                                          />
                                        </div>
                                      )}
                                    </div>
                                  ))}
                                </div>
                              </AccordionBody>
                            </AccordionItem>
                          </Accordion>
                        </div>
                      </>
                    ) : null}
                  </>
                ) : null}
                <Button className="submit-button" onClick={handleOnSave(index)}>
                  {isLoading && showSpinner(index) ? (
                    <Spinner size="sm" className="spinner" />
                  ) : null}
                  Save
                </Button>
              </div>
            </div>
          </Wrapper>
        );
      })}
    </>
  );
};
export default NotificationsList;
